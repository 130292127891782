import env from '.env'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
/* Paths */
import { TRANSACTIONS_PATH, RETAIL_PATH } from 'routes/paths/root'
/* Helpers */
import Spinner from 'components/helpers/spinner'

const PeachPaymentModal = props => {
  const { peachCheckoutId, show, onHide, transactionDetails } = props

  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const url = `${env?.peach?.url}/v1/paymentWidgets.js?checkoutId=${peachCheckoutId}`
    const script = document.createElement('script')
    script.src = url
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  })

  useEffect(() => {
    location.hash && history.push(`${RETAIL_PATH}${TRANSACTIONS_PATH}/${location.hash.substring(1)}`)
  }, [location])

  const containerClass = location.pathname.includes('retail') ? 'retail' : 'wholesale'

  return (
    <Modal contentClassName={`well-dark ${env.retail.theme}`} className={containerClass} show={show}>
      {peachCheckoutId ? (
        <React.Fragment>
          <Modal.Header className="p-3 m-2 bg-white">
            <Modal.Title as={'h4'}>
              Transaction Payment
              <button onClick={onHide} className="border-0 checkLocal">
                ×
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-white px-4 pt-4 pb-0 mx-2 mb-5">
            <form
              action={`#${transactionDetails.transaction_id}/status`}
              className="paymentWidgets"
              data-brands="VISA MASTER AMEX"
            />
          </Modal.Body>
        </React.Fragment>
      ) : (
        <Spinner spinner={false} />
      )}
    </Modal>
  )
}

PeachPaymentModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  peachCheckoutId: PropTypes.any,
  show: PropTypes.bool,
  transactionDetails: PropTypes.shape({
    transaction_id: PropTypes.number,
  }),
}

export default PeachPaymentModal
