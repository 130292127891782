import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
/* Helpers */
import renderErrors from 'components/helpers/render_errors'
import { TextField, SelectField } from 'components/helpers/formik_fields'

// eslint-disable-next-line react/display-name
const BeneficiaryDetails = forwardRef(
  (
    {
      errors,
      relationshipTypes,
      beneficiary,
      recipientAddress,
      recipientDetails,
      onBeneficiaryAddressChange,
      onRelationshipTypeChange,
      isNewBeneficiary,
    },
    beneficiaryDetailsFormRef,
  ) => {
    const requiredObjects = { ...recipientAddress, ...recipientDetails }
    const requiredFields = Object.keys(requiredObjects).reduce(
      (obj, field) => ({
        ...obj,
        [`is${field.charAt(0).toUpperCase() + field.slice(1)}Required`]: requiredObjects?.[field].required,
      }),
      {},
    )
    const ignoredFields = [
      'birth_date',
      ...(isNewBeneficiary ? ['first_name', 'last_name', 'middle_name'] : []),
      'payment_reference',
      'suburb',
      'relation_with_customer',
    ]

    const initialValues = {
      address: {
        address_line_1: beneficiary?.address?.address_line_1 || '',
        address_line_2: beneficiary?.address?.address_line_1 || '',
        ...requiredFields,
        city: beneficiary?.address?.city || '',
        email: beneficiary?.address?.email || '',
        first_name: beneficiary?.address?.first_name || '',
        last_name: beneficiary?.address?.last_name || '',
        middle_name: beneficiary?.address?.middle_name || '',
        mobile: beneficiary?.address?.mobile || '',
        post_code: beneficiary?.address?.post_code || '',
        province: beneficiary?.address?.province || '',
      },
    }

    const isRelationWithCustomerEnabled = recipientDetails?.relation_with_customer?.enabled
    const isRelationWithCustomerRequired = recipientDetails?.relation_with_customer?.required || false
    if (isRelationWithCustomerEnabled) {
      initialValues.relation_with_customer = beneficiary?.relation_with_customer
    }

    const handleBeneficiaryAddressChange = (e, { setFieldValue }) => {
      const { name, value } = e.target
      onBeneficiaryAddressChange(name.split('.')[1], value)
      setFieldValue(name, value)
    }

    return (
      <div className="px-0">
        <Formik
          enableReinitialize
          innerRef={beneficiaryDetailsFormRef}
          initialValues={initialValues}
          initialErrors={initialValues}
          validationSchema={Yup.object().shape({
            address: Yup.object().shape({
              address_line_1: Yup.string().when('isAddress_line_1Required', {
                is: true,
                then: Yup.string().required("Can't be blank").nullable(),
              }),
              address_line_2: Yup.string().when('isAddress_line_2Required', {
                is: true,
                then: Yup.string().required("Can't be blank").nullable(),
              }),
              city: Yup.string().when('isCityRequired', {
                is: true,
                then: Yup.string().required("Can't be blank").nullable(),
              }),
              isAddress_line_1Required: Yup.boolean(),
              isAddress_line_2Required: Yup.boolean(),
              isCityRequired: Yup.boolean(),
              isMiddle_nameRequired: Yup.boolean(),
              isMobileRequired: Yup.boolean(),
              isPost_codeRequired: Yup.boolean(),
              isProvinceRequired: Yup.boolean(),
              isSuburbRequired: Yup.boolean(),
              middle_name: Yup.string()
                .min(1, 'Must be 1 letter or more')
                .max(35, 'Must be 35 letters or less')
                .matches(/^[a-zA-Z ]+$/, 'Special characters not allowed')
                .when('isMiddle_nameRequired', {
                  is: true,
                  then: Yup.string().required("Can't be blank").nullable(),
                }),
              mobile: Yup.string().when('isMobileRequired', {
                is: true,
                then: Yup.string()
                  .required("Can't be blank")
                  .nullable()
                  .matches(/^[^0]/, 'Must not start with 0')
                  .matches(/^[0-9]+$/, 'Special characters not allowed')
                  .min(7, 'Must be 7 digits or more')
                  .max(10, 'Must be 10 digits or less'),
              }),
              post_code: Yup.string().when('isPost_codeRequired', {
                is: true,
                then: Yup.string().required("Can't be blank").nullable(),
              }),
              province: Yup.string().when('isProvinceRequired', {
                is: true,
                then: Yup.string().required("Can't be blank").nullable(),
              }),
              suburb: Yup.string().when('isSuburbRequired', {
                is: true,
                then: Yup.string().required("Can't be blank").nullable(),
              }),
            }),
            relation_with_customer: isRelationWithCustomerEnabled
              ? isRelationWithCustomerRequired
                ? Yup.string().required("Can't be blank").nullable()
                : Yup.string()
              : undefined,
          })}
          onSubmit={(values, actions) => {}}>
          {formik => (
            <Form>
              {recipientDetails &&
                Object.keys(recipientDetails).map(
                  inputName =>
                    recipientDetails?.[inputName]?.enabled &&
                    !ignoredFields.includes(inputName) && (
                      <div className="form-group mb-0">
                        <TextField
                          key={`address.${inputName}`}
                          onChange={e => handleBeneficiaryAddressChange(e, formik)}
                          value={beneficiary.address[inputName]}
                          label={recipientDetails[inputName]?.name}
                          name={`address.${inputName}`}
                          placeholder={recipientDetails[inputName]?.placeholder}
                          required={recipientDetails[inputName]?.required}
                        />
                        {renderErrors(errors[`address.${inputName}`])}
                      </div>
                    ),
                )}
              <div>
                {recipientAddress &&
                  Object.keys(recipientAddress).map(addressInputName => {
                    if (
                      !recipientAddress[addressInputName] ||
                      !recipientAddress[addressInputName].enabled ||
                      ignoredFields.includes(addressInputName)
                    ) {
                      return null
                    }
                    if (addressInputName === 'mobile') {
                      return (
                        <div className="form-group mb-0">
                          <label
                            className={`control-label${
                              recipientAddress?.mobile?.required ? ' required' : ''
                            } field-label`}>
                            {recipientAddress.mobile.name}
                          </label>
                          <div className="d-flex justify-content-between p-0">
                            <div className="w-25">
                              <TextField
                                key="address.mobile_isd_code"
                                name="address.mobile_isd_code"
                                placeholder="+000"
                                type="text"
                                value={beneficiary?.address?.mobile_isd_code}
                                onChange={e => handleBeneficiaryAddressChange(e, formik)}
                              />
                            </div>
                            <div className="w-75 pl-2">
                              <TextField
                                key="address.mobile"
                                name="address.mobile"
                                placeholder="123456789"
                                type="text"
                                value={beneficiary?.address?.mobile}
                                onChange={e => handleBeneficiaryAddressChange(e, formik)}
                              />
                            </div>
                          </div>

                          {renderErrors(errors['address.mobile_isd_code'])}
                          {renderErrors(errors['address.mobile'])}
                        </div>
                      )
                    }
                    return (
                      <div key={addressInputName} className="form-group mb-0">
                        <TextField
                          key={`address.${addressInputName}`}
                          onChange={e => handleBeneficiaryAddressChange(e, formik)}
                          value={beneficiary.address[addressInputName]}
                          name={`address.${addressInputName}`}
                          label={recipientAddress[addressInputName].name}
                          placeholder={recipientAddress[addressInputName].placeholder}
                          required={recipientAddress[addressInputName].required}
                        />
                        {renderErrors(errors[`address.${addressInputName}`])}
                      </div>
                    )
                  })}
              </div>
              <div>
                {isRelationWithCustomerEnabled && (
                  <div className="form-group row">
                    <div className="col-12">
                      <SelectField
                        key="relation_with_customer"
                        label={recipientDetails.relation_with_customer?.name}
                        name="relation_with_customer"
                        placeholder={recipientDetails.relation_with_customer?.placeholder}
                        required={recipientDetails.relation_with_customer?.required}
                        onChange={relationshipType => onRelationshipTypeChange(relationshipType.value)}
                        value={relationshipTypes.find(
                          relationshipType => relationshipType.value === beneficiary.relation_with_customer,
                        )}
                        options={relationshipTypes}
                      />
                      {renderErrors(errors?.relation_with_customer)}
                    </div>
                  </div>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    )
  },
)

BeneficiaryDetails.propTypes = {
  beneficiary: PropTypes.shape({
    account: PropTypes.shape({
      address: PropTypes.shape({
        country_code: PropTypes.string,
      }),
    }),
    account_type_name: PropTypes.string,
    address: PropTypes.shape({
      address_line_1: PropTypes.string,
      address_line_2: PropTypes.string,
      city: PropTypes.string,
      country_code: PropTypes.string,
      email: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      middle_name: PropTypes.string,
      mobile: PropTypes.string,
      mobile_isd_code: PropTypes.string,
      post_code: PropTypes.string,
      province: PropTypes.string,
      zip: PropTypes.string,
    }),
    relation_with_customer: PropTypes.string,
    short_name: PropTypes.string,
  }),
  countriesList: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.shape({
    'address.address_line_1': PropTypes.any,
    'address.address_line_2': PropTypes.any,
    'address.city': PropTypes.any,
    'address.country_code': PropTypes.any,
    'address.first_name': PropTypes.any,
    'address.full_name': PropTypes.any,
    'address.last_name': PropTypes.any,
    'address.middle_name': PropTypes.any,
    'address.mobile': PropTypes.any,
    'address.mobile_isd_code': PropTypes.any,
    'address.post_code': PropTypes.any,
    'address.province': PropTypes.any,
    relation_with_customer: PropTypes.any,
    short_name: PropTypes.any,
  }),
  identificationTypesE4F: PropTypes.array,
  isBankTransference: PropTypes.func,
  isNewBeneficiary: PropTypes.bool,
  onBeneficiaryAddressChange: PropTypes.func.isRequired,
  onBeneficiaryShortNameChange: PropTypes.func.isRequired,
  onRelationshipTypeChange: PropTypes.func.required,
  recipientAddress: PropTypes.shape({
    address_line_1: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    address_line_2: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    city: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    email: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    mobile: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      required: PropTypes.bool,
    }),
    post_code: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    province: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      required: PropTypes.bool,
    }),
  }),
  recipientDetails: PropTypes.shape({
    first_name: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    last_name: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    middle_name: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    relation_with_customer: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
    short_name: PropTypes.shape({
      enabled: PropTypes.bool,
      name: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
    }),
  }),
  relationshipTypes: PropTypes.array,
}

export default BeneficiaryDetails
