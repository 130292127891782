import { toArray } from 'lodash'
import {
  accountSettings,
  authorizations,
  addresses,
  notificationSettings,
  notificationSettingsUpdate,
  securityAnswers,
  securityAnswersUpdate,
  show,
  updateUser,
} from 'api/accounts/cw/users'

import { securityQuestions } from 'api/accounts/public/secret_questions'
import { interfaces } from 'api/accounts/cw/settings'
import { settings as tradingSettings } from 'api/trading/cw/settings'
import { settings as tradingForwardsSettings } from 'api/trading/cw/forwards'
import { accepted as tradingTransactionAccepted } from 'api/trading/cw/customers/transactions'
import {
  linkedAccounts,
  getIdentificationInformations,
  getCustomerInformation,
  updateIdentificationInformation,
  riskAssesmentInformations,
  updateRiskAssesmentInformation,
  updateCustomerInformation,
} from 'api/accounts/cw/customers'

const SET_SSO = 'SET_SSO'
const SET_TOKEN_INFORMATION = 'SET_TOKEN_INFORMATION'
const SET_CURRENT_USER_DATA = 'SET_CURRENT_USER_DATA'
const SET_CURRENT_USER_ADDRESSES = 'SET_CURRENT_USER_ADDRESSES'
const SET_CURRENT_USER_ACCOUNT_SETTINGS = 'SET_CURRENT_USER_ACCOUNT_SETTINGS'
const SET_CURRENT_USER_LINKED_ACCOUNTS = 'SET_CURRENT_USER_LINKED_ACCOUNTS'
const SET_CURRENT_USER_PERMISSIONS = 'SET_CURRENT_USER_PERMISSIONS'
const SIGN_OUT = 'SIGN_OUT'
const SET_CURRENT_USER_INFO = 'SET_CURRENT_USER_INFO'
const SET_CURRENT_USER_NOTIFICATION_SETTINGS = 'SET_CURRENT_USER_NOTIFICATION_SETTINGS'
const SET_CURRENT_USER_SECURITY_ANSWER_LIST = 'SET_CURRENT_USER_SECURITY_ANSWER_LIST'
const SET_CURRENT_USER_SECURITY_QUESTION_LIST = 'SET_CURRENT_USER_SECURITY_QUESTION_LIST'
const SESSION_EXPIRED = 'SESSION_EXPIRED'
const SET_CURRENT_USER_DETAILS = 'SET_CURRENT_USER_DETAILS'
const SET_CURRENT_USER_IDENTIFICATION_INFO = 'SET_CURRENT_USER_IDENTIFICATION_INFO'
const SET_CUSTOMER_RISK_ASSESMENT_INFO = 'SET_CUSTOMER_RISK_ASSESMENT_INFO'
const REFRESH_SESSION = 'REFRESH_SESSION'
const SET_SOURCE_OF_FUNDS = 'SET_SOURCE_OF_FUNDS'
const SET_ADDRESS_FIELD = 'SET_ADDRESS_FIELD'

const setUserDetails = userId => dispatch =>
  show(userId)
    .then(res => {
      res && dispatch({ payload: res.data, type: SET_CURRENT_USER_DETAILS })
    })
    .catch(err => {
      throw err
    })

const updateUserDetails = (userId, data, contentType = '') => dispatch =>
  updateUser(userId, data, contentType)
    .then(response => {
      response && dispatch({ payload: response?.data, type: SET_CURRENT_USER_DETAILS })
      return response
    })
    .catch(error => {
      throw error
    })

const setTokenInformation = payload => ({ payload, type: SET_TOKEN_INFORMATION })

const setSSO = () => ({ type: SET_SSO })

const sessionExpired = () => ({ type: SESSION_EXPIRED })

const refreshSession = () => dispatch => dispatch({ type: REFRESH_SESSION })

const signOut = () => ({ type: SIGN_OUT })

const setCurrentUserData = payload => ({ payload, type: SET_CURRENT_USER_DATA })

const updateSourceOfFunds = payload => ({ payload, type: SET_SOURCE_OF_FUNDS })

const updateAddressField = payload => ({ payload, type: SET_ADDRESS_FIELD })

const setCurrentUserAddress = userId => dispatch =>
  addresses(userId)
    .then(res => {
      res &&
        dispatch({
          payload: res.data,
          type: SET_CURRENT_USER_ADDRESSES,
        })
    })
    .catch(error => {
      throw error
    })

const setCurrentUserPermissions = userId => dispatch =>
  Promise.all([
    authorizations(userId),
    interfaces(),
    tradingSettings(),
    tradingForwardsSettings(),
    tradingTransactionAccepted(),
  ])
    .then(
      ([
        authorizationPermissions,
        interfacePermissions,
        tradingPermissions,
        tradingForwardsPermissions,
        transactionPermissions,
      ]) => {
        dispatch({
          payload: {
            authorizationPermissions: authorizationPermissions && authorizationPermissions.data,
            interfacePermissions: interfacePermissions && interfacePermissions.data,
            tradingForwardsPermissions: tradingForwardsPermissions && tradingForwardsPermissions.data,
            tradingPermissions: tradingPermissions && tradingPermissions.data,
            transactionPermissions: transactionPermissions && transactionPermissions.data,
          },
          type: SET_CURRENT_USER_PERMISSIONS,
        })
      },
    )
    .catch(error => {
      throw error
    })

const setCurrentUserLinkedAccounts = customerId => dispatch =>
  linkedAccounts(customerId)
    .then(res => {
      res &&
        dispatch({
          payload: res.data,
          type: SET_CURRENT_USER_LINKED_ACCOUNTS,
        })
    })
    .catch(error => {
      throw error
    })

const setCurrentUserAccountSettings = ({ userId }) => dispatch =>
  accountSettings(userId)
    .then(res => {
      res &&
        dispatch({
          payload: res.data,
          type: SET_CURRENT_USER_ACCOUNT_SETTINGS,
        })
    })
    .catch(error => {
      throw error
    })

const setCurrentUserNotificationSettings = ({ userId }) => dispatch =>
  notificationSettings(userId)
    .then(
      res =>
        res &&
        dispatch({
          payload: res.data,
          type: SET_CURRENT_USER_NOTIFICATION_SETTINGS,
        }),
    )
    .catch(error => {
      throw error
    })

const updateCurrentUserNotificationSettings = ({ userId, data }) => dispatch =>
  notificationSettingsUpdate(userId, data)
    .then(res => {
      res &&
        dispatch({
          payload: res.data,
          type: SET_CURRENT_USER_NOTIFICATION_SETTINGS,
        })
      return res
    })
    .catch(error => {
      throw error
    })

const setCurrentUserSecurityAnswerList = ({ userId }) => dispatch =>
  securityAnswers(userId)
    .then(res => {
      res &&
        dispatch({
          payload: [...res.data],
          type: SET_CURRENT_USER_SECURITY_ANSWER_LIST,
        })
    })
    .catch(error => {
      throw error
    })

const updateCurrentUserSecurityAnswers = ({ userId, data }) => dispatch =>
  securityAnswersUpdate(userId, data)
    .then(res => {
      res &&
        dispatch({
          payload: toArray(data.user.secret_answers_attributes),
          type: SET_CURRENT_USER_SECURITY_ANSWER_LIST,
        })
      return res
    })
    .catch(error => {
      return error
    })

const setSecurityQuestionList = () => dispatch =>
  securityQuestions()
    .then(res => {
      res &&
        dispatch({
          payload: res.data,
          type: SET_CURRENT_USER_SECURITY_QUESTION_LIST,
        })
    })
    .catch(error => {
      throw error
    })

const setIdentificationInformations = (customerId, id) => dispatch =>
  getIdentificationInformations(customerId, id)
    .then(response => {
      dispatch({
        payload: response?.data,
        type: SET_CURRENT_USER_IDENTIFICATION_INFO,
      })
    })
    .catch(error => {
      throw error
    })

const setCustomerInformation = (customerId, id) => dispatch =>
  getCustomerInformation(customerId, id)
    .then(response => {
      dispatch({
        payload: response?.data,
        type: SET_CURRENT_USER_INFO,
      })
    })
    .catch(error => {
      throw error
    })

const setRiskAssesmentInformation = userId => dispatch =>
  riskAssesmentInformations(userId)
    .then(response => {
      dispatch({
        payload: response?.data,
        type: SET_CUSTOMER_RISK_ASSESMENT_INFO,
      })
    })
    .catch(error => {
      throw error
    })

const updateIdentificationInfo = (data, customerId) => dispatch =>
  updateIdentificationInformation(customerId, data)
    .then(response => {
      response &&
        dispatch({
          payload: response?.data,
          type: SET_CURRENT_USER_IDENTIFICATION_INFO,
        })
      return response
    })
    .catch(error => {
      throw error
    })

const updateRiskAssesmentInfo = (customerId, id, data) => dispatch =>
  updateRiskAssesmentInformation(customerId, id, data)
    .then(response => {
      response &&
        dispatch({
          payload: response?.data,
          type: SET_CUSTOMER_RISK_ASSESMENT_INFO,
        })
      return response
    })
    .catch(error => {
      throw error
    })

const updateCustomerInfo = ({ customerId, id, data }) => dispatch =>
  updateCustomerInformation(customerId, id, data)
    .then(response => {
      response &&
        dispatch({
          payload: data?.customer,
          type: SET_CURRENT_USER_INFO,
        })
      return response
    })
    .catch(error => {
      throw error
    })

export {
  SIGN_OUT,
  SESSION_EXPIRED,
  SET_TOKEN_INFORMATION,
  SET_CURRENT_USER_DATA,
  SET_CURRENT_USER_LINKED_ACCOUNTS,
  SET_CURRENT_USER_ACCOUNT_SETTINGS,
  SET_CURRENT_USER_PERMISSIONS,
  SET_CURRENT_USER_ADDRESSES,
  SET_SSO,
  SET_CURRENT_USER_NOTIFICATION_SETTINGS,
  SET_CURRENT_USER_SECURITY_ANSWER_LIST,
  SET_CURRENT_USER_SECURITY_QUESTION_LIST,
  SET_CURRENT_USER_DETAILS,
  SET_CURRENT_USER_IDENTIFICATION_INFO,
  SET_CURRENT_USER_INFO,
  SET_CUSTOMER_RISK_ASSESMENT_INFO,
  SET_SOURCE_OF_FUNDS,
  SET_ADDRESS_FIELD,
  REFRESH_SESSION,
  signOut,
  sessionExpired,
  setTokenInformation,
  setCurrentUserData,
  setCurrentUserAddress,
  setCurrentUserAccountSettings,
  setCurrentUserLinkedAccounts,
  setCurrentUserPermissions,
  setSSO,
  setCurrentUserNotificationSettings,
  updateCurrentUserNotificationSettings,
  setCurrentUserSecurityAnswerList,
  setCustomerInformation,
  updateCurrentUserSecurityAnswers,
  setSecurityQuestionList,
  setUserDetails,
  updateUserDetails,
  setIdentificationInformations,
  updateIdentificationInfo,
  setRiskAssesmentInformation,
  updateRiskAssesmentInfo,
  updateCustomerInfo,
  refreshSession,
  updateSourceOfFunds,
  updateAddressField,
}
