/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */
/* eslint-disable indent */
import React, { useState, useEffect, useCallback } from 'react'
import { Formik, Form } from 'formik'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
/* Helpers */
import Spinner from 'components/helpers/spinner'
import { SelectField, CalendarField, TextField } from 'components/helpers/formik_fields'
import { useFetchAddressOptions } from 'components/hooks/useFetchAddressOptions'
import { fetchInitialCascade } from 'components/utils/fetch_initial_address_options'
/* Validations */
import { personalDetailsConfirmValidation } from 'validations'
/* API */
import { getDocumentTypes, getRegistrationEnabledCountries } from 'api/dropdowns'
import { getIdentificationInformations, updateIdentificationInformation } from 'api/accounts/cw/customers'
/* Actions */
import { updateUserDetails } from 'actions/users'
import { NEW_FULL_PATH as TRANSACTION_NEW_FULL_PATH } from 'routes/paths/private/transactions'

const addressAttributes = { province: 'Province', city: 'City', suburb: 'Suburb', zip: 'Zip' }

const PersonalDetailsConfirm = ({ user, userId, updateUserDetails, ...props }) => {
  const history = useHistory()
  const [countries, setCountries] = useState()
  const [serverValidationErrors, setServerValidationErrors] = useState({})

  const [
    dropdowns,
    setDropdowns,
    isLoading,
    setIsLoading,
    fetchDropdownOptions,
    addressAttributesRef,
    newFormikStateObj,
  ] = useFetchAddressOptions(addressAttributes)

  const fetchIdentificationInformations = useCallback(async () => {
    const response = await getIdentificationInformations(userId, userId).catch(error =>
      toast.error(error?.response?.data?.description),
    )
    response && setDropdowns(prevState => ({ ...prevState, identificationInfo: response.data }))
  }, [])

  const fetchDocumentTypes = useCallback(async () => {
    const response = await getDocumentTypes().catch(error => toast.error(error?.response?.data?.description))
    response && setDropdowns(prevState => ({ ...prevState, documentTypes: response }))
  }, [])

  useEffect(() => {
    async function fetchRegistrationEnabledCountries() {
      try {
        const res = await getRegistrationEnabledCountries()
        res &&
          setCountries(
            res.map(country => ({
              id: country.id,
              label: country.name,
              value: country.code,
            })),
          )
      } catch (error) {
        toast.error(error?.response?.data?.description)
      }
    }
    fetchRegistrationEnabledCountries()
    fetchIdentificationInformations()
    fetchDocumentTypes()

    if (!isEmpty(user?.address)) {
      const pickedInitialValues = (({ province, city, suburb, zip }) => ({ province, city, suburb, zip }))(
        user?.address,
      )
      fetchInitialCascade(addressAttributes, setDropdowns, setIsLoading, pickedInitialValues, 'address')
    }
  }, [user?.address])

  /** Loads spinner while waiting for content */
  if (isEmpty(user) || !countries) {
    return <Spinner />
  }

  const onProvinceChange = (option, dropdownSection, fieldName, { setValues, values }) => {
    const { address } = values

    setValues({
      ...values,
      [dropdownSection]: { ...values[dropdownSection], ...newFormikStateObj(fieldName, option.value) },
    })

    fieldName !== 'zip' && fetchDropdownOptions(fieldName, option.value, address, dropdownSection)
  }

  const handleSubmit = async (values, actions) => {
    props.setButtonStatus?.(true)
    const { address, email } = values
    const { mobile, mobile_isd_code: mobileIsdCode, ...restValues } = address

    const updateAdressesObj = {
      user: {
        address_attributes: {
          ...restValues,
          first_name: user?.address?.first_name,
          last_name: user?.address?.last_name,
          // TO DO: Remove when API will be fixed
          middle_name: user?.address?.middle_name || ' ',
          mobile,
          mobile_isd_code: mobileIsdCode.replace(/\+/g, ''),
        },
        email,
      },
    }

    const updateIdentificationObj = {
      id_information: {
        id_card_attributes: {
          expiry_date_day: new Date(values.id_card.expiry_date_year).getDate(),
          expiry_date_month: new Date(values.id_card.expiry_date_year).getMonth() + 1,
          expiry_date_year: new Date(values.id_card.expiry_date_year).getFullYear(),
          identity_type_id: values.id_card.identity_type_id,
          number: values.id_card.number,
        },
      },
    }

    const result = await Promise.allSettled([
      updateUserDetails(userId, updateAdressesObj, 'application/json').catch(error => {
        if (error.response.status === 422) {
          setServerValidationErrors(Object.assign(serverValidationErrors, error?.response?.data?.errors))
        } else {
          toast.error(error?.response?.data?.description)
        }
      }),
      updateIdentificationInformation(userId, updateIdentificationObj).catch(error => {
        if (error.response.status === 422) {
          toast.error(error?.response?.data?.description)
          setServerValidationErrors(Object.assign(serverValidationErrors, error?.response?.data?.errors))
        } else {
          toast.error(error?.response?.data?.description)
        }
      }),
    ])
    !isEmpty(serverValidationErrors) && actions.setStatus(serverValidationErrors)
    actions.setSubmitting(false)
    props.setButtonStatus?.(false)
    setServerValidationErrors({})
    if (result.every(promise => promise.value)) {
      toast.success('Successfully updated identification information')
      if (props.onSubmit) {
        props.onSubmit(actions)
        history.push(`${TRANSACTION_NEW_FULL_PATH}`)
      }
      props.onHide()
    }
  }

  return (
    <div>
      {!user ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <div className="personal-ctn">
            <div>
              {props.onHide && (
                <button onClick={props.onHide} className="close-btn">
                  ×
                </button>
              )}

              <div>
                <h5>Personal details</h5>
              </div>
            </div>
            <Formik
              enableReinitialize
              innerRef={addressAttributesRef}
              initialValues={{
                address: {
                  address_line_1: user.address?.address_line_1 || '',
                  city: user.address?.city || '',
                  country_code: countries?.find(c => c.label === user.address?.country_name)?.value || '',
                  mobile: user.address?.mobile || '',
                  mobile_isd_code: user.address?.mobile_isd_code || '',
                  province: user.address?.province || '',
                  suburb: user.address?.suburb || '',
                  zip: user.address?.zip || '',
                },
                email: user.email || '',
                id_card: {
                  expiry_date_year:
                    Date.parse(
                      `${dropdowns?.identificationInfo?.id_card?.expiry_date_year || ''}-${
                        dropdowns?.identificationInfo?.id_card?.expiry_date_month || ''
                      }-${dropdowns?.identificationInfo?.id_card?.expiry_date_day || ''}`,
                    ) || '',
                  identity_type_id:
                    dropdowns?.documentTypes?.find(
                      docType => docType.value === dropdowns?.identificationInfo?.id_card?.identity_type_id,
                    )?.value || '',
                  number: dropdowns?.identificationInfo?.id_card?.number || '',
                },
              }}
              validationSchema={personalDetailsConfirmValidation}
              onSubmit={handleSubmit}>
              {formik => (
                <Form id="PersonalDetailsConfirm">
                  <div>
                    <TextField label="Email address" name="email" type="text" />
                    <div className="form-group row">
                      <div className="col-12">
                        <label className="control-label">Mobile number</label>
                        <div className="d-flex justify-content-between col-12 p-0">
                          <div className="w-25">
                            <TextField name="address.mobile_isd_code" placeholder="+000" type="text" />
                          </div>
                          <div className="w-75 pl-2">
                            <TextField name="address.mobile" placeholder="(000) 123-1234" type="text" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <TextField
                      data-testid="PersonalDetailsConfirm-address_line_1"
                      label="Street address"
                      name="address.address_line_1"
                      type="text"
                    />

                    {Object.keys(addressAttributes).map(inputName => (
                      <div key={inputName} className="mt-3">
                        <SelectField
                          key={inputName}
                          data-testid={`PersonalDetailsConfirm-${inputName}`}
                          label={addressAttributes[inputName]}
                          name={`address.${inputName}`}
                          options={dropdowns?.address?.[inputName]}
                          onChange={option => onProvinceChange(option, 'address', inputName, formik)}
                          isLoading={isLoading?.address?.[inputName] || isLoading?.address?.[inputName] === undefined}
                        />
                      </div>
                    ))}
                    <div className="mt-3">
                      <SelectField
                        label="Country of Residence"
                        name="address.country_code"
                        options={countries}
                        isLoading={!countries}
                      />
                    </div>
                    <div className="mt-3">
                      <SelectField
                        label="Identification type"
                        name="id_card.identity_type_id"
                        options={dropdowns?.documentTypes}
                        isLoading={!dropdowns?.documentTypes}
                      />
                    </div>
                    <div className="form-control-container mt-3">
                      <div className="row">
                        <div className="col-md-12 d-flex">
                          <div className="w-100">
                            <TextField label="Document ID" name="id_card.number" />
                          </div>

                          {dropdowns?.documentTypes?.find(
                            docType => docType.value === formik.values.id_card.identity_type_id,
                          )?.expiry_date && (
                            <div className="w-100 ml-2">
                              <CalendarField
                                label="Expiry date"
                                name="id_card.expiry_date_year"
                                calendarMinDate={new Date()}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <button
                      disabled={formik.isSubmitting}
                      form="PersonalDetailsConfirm"
                      type="submit"
                      style={{ height: '50px', width: 'fit-content' }}
                      className="w-inline-block-button bg-white mt-4">
                      {props.btnText ? props.btnText : 'Update changes'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

const mapStateToProps = ({ user }) => ({
  user: user.data?.userDetails,
  userId: user.data?.userId,
})

PersonalDetailsConfirm.propTypes = {
  btnText: PropTypes.string,
  customHead: PropTypes.element,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  personalDetails: PropTypes.shape({
    email: PropTypes.string,
  }),
  setButtonStatus: PropTypes.func,
  show: PropTypes.bool,
  updateUserDetails: PropTypes.func,
  user: PropTypes.shape({
    address: PropTypes.shape({
      address_line_1: PropTypes.string,
      city: PropTypes.string,
      country_code: PropTypes.string,
      country_name: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      middle_name: PropTypes.string,
      mobile: PropTypes.string,
      mobile_isd_code: PropTypes.string,
      province: PropTypes.string,
      suburb: PropTypes.string,
      zip: PropTypes.string,
    }),
    email: PropTypes.string,
    principal_category_id: PropTypes.number,
    user_id: PropTypes.number,
  }),
  userId: PropTypes.string,
}

export default connect(mapStateToProps, { updateUserDetails })(PersonalDetailsConfirm)
