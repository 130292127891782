import { REGISTER_PATH } from '../root'

const NEW_PATH = 'new'
const STEP_TWO = 'transfer-limit'
const STEP_THREE = 'account-credentials'
const STEP_FOUR_PERSONAL_INFORMATION = 'personal-information'
const STEP_FOUR_PHYSICAL_ADDRESS = 'physical-address'
const STEP_FOUR_OTP_VALIDATION = 'otp-validation'
const STEP_FIVE = 'identification-information'
const STEP_SIX_PROMOTIONS = 'promotions'
const STEP_SIX_SUCCESS = 'success'
const NEW_FULL_PATH = `${REGISTER_PATH}/${NEW_PATH}`
const HOW_IT_WORKS = 'https://www.superswift.co.za/how-it-works'

export {
  NEW_PATH,
  NEW_FULL_PATH,
  STEP_TWO,
  STEP_THREE,
  STEP_FOUR_PERSONAL_INFORMATION,
  STEP_FOUR_PHYSICAL_ADDRESS,
  STEP_FOUR_OTP_VALIDATION,
  STEP_FIVE,
  STEP_SIX_PROMOTIONS,
  STEP_SIX_SUCCESS,
  HOW_IT_WORKS,
}
