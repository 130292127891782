/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'components/helpers/spinner'
import BeneficiaryDetails from '../modules/beneficiary_details'
import PayoutLocation from '../modules/payout_location'
import MobileWallet from '../modules/mobile_wallet'
import Checkbox from 'components/helpers/form_fields/checkbox'
import renderErrors from 'components/helpers/render_errors'

const SecondPage = forwardRef((props, beneficiaryDetailsFormRef) => {
  const {
    errors,
    isRenumerationExternal,
    isBankTransference,
    isRenumerationMobile,
    onBeneficiaryIsDefaultChange,
    beneficiary,
    fetchingBeneficiaryData,
  } = props

  return (
    <React.Fragment>
      <BeneficiaryDetails {...props} ref={beneficiaryDetailsFormRef} />
      {isRenumerationExternal() && <PayoutLocation {...props} />}
      {isRenumerationMobile() && <MobileWallet {...props} />}

      {fetchingBeneficiaryData ? (
        <Spinner text={true} />
      ) : (
        <div className="container-fluid px-0">
          {!isBankTransference() && (
            <div className="form-group mb-0">
              <Checkbox
                onChange={onBeneficiaryIsDefaultChange}
                checked={beneficiary?.is_default}
                message="Save as default beneficiary"
              />
              {renderErrors(errors?.is_default)}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  )
})

SecondPage.propTypes = {
  beneficiary: PropTypes.shape({
    address: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    is_default: PropTypes.bool,
  }),
  displayPage: PropTypes.number,
  errors: PropTypes.shape({
    is_default: PropTypes.bool,
  }),
  fetchingBeneficiaryData: PropTypes.bool,
  goNextPage: PropTypes.func,
  isBankTransference: PropTypes.func,
  isLoading: PropTypes.shape({
    formSubmit: PropTypes.bool,
  }),
  isRenumerationExternal: PropTypes.func,
  isRenumerationMobile: PropTypes.func,
  isRenumerationSwift: PropTypes.func,
  onBeneficiaryIsDefaultChange: PropTypes.func,
}

export default SecondPage
