/* eslint-disable indent */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'
/** Actions */
import { setRiskAssesmentInformation, updateRiskAssesmentInfo, updateUserDetails } from 'actions/users'
/* API */
import { sourceOfFunds as getSourceOfFunds, getOccupationTypes, getTitles } from 'api/dropdowns'
/* Helpers */
import { TextField, SelectField } from 'components/helpers/formik_fields'
import Spinner from 'components/helpers/spinner'

/**
 * Component for Account - ProfileInformation
 */
export const ProfileInformation = ({
  user,
  userId,
  riskAssesmentInfo,
  setRiskAssesmentInformation,
  updateRiskAssesmentInfo,
  updateUserDetails,
  sourcesOfFunds,
}) => {
  const [options, setDropdownOptions] = useState([])
  const [serverValidationErrors, setServerValidationErrors] = useState({})

  const fetchInitialOptions = useCallback(async () => {
    async function fetchSourceOfFundsOptions() {
      return isEmpty(sourcesOfFunds)
        ? await getSourceOfFunds()
            .then(res => res.data)
            .catch(error => toast.error(error.response?.data.description))
        : sourcesOfFunds
    }

    await Promise.all([fetchSourceOfFundsOptions(), getOccupationTypes(), getTitles()])
      .then(([SOF, occupation, titles]) => setDropdownOptions(prevState => ({ ...prevState, SOF, occupation, titles })))
      .catch(error => toast.error(error?.response?.data?.description))
  }, [])

  useEffect(() => {
    fetchInitialOptions()
    isEmpty(riskAssesmentInfo) && setRiskAssesmentInformation(userId)
  }, [userId, setRiskAssesmentInformation, riskAssesmentInfo, fetchInitialOptions])

  if (isEmpty(user) || isEmpty(riskAssesmentInfo)) {
    return <Spinner />
  }

  return (
    <div className="accounts-card col-xl-9">
      <div className="tdb-card_title-body">
        <h2>Profile information</h2>
        <div className="tdb-card_divider" />
      </div>
      <div className="tdb-card_content-body d-flex flex-column justify-content-between align-items-start pt-2">
        <div className="col w-100 pr-0 pl-0">
          <Formik
            initialValues={{
              address: {
                first_name: user.address?.first_name,
                last_name: user.address?.last_name,
                middle_name: user.address?.middle_name || '',
                title: user.personal_info?.title,
              },
              risk_assesment_information: {
                sources_of_funds:
                  sourcesOfFunds.find(c => c.label === riskAssesmentInfo.sources_of_funds?.[0])?.value || '',
              },
            }}
            validationSchema={Yup.object().shape({
              address: Yup.object().shape({
                first_name: Yup.string()
                  .min(1, 'Must be 1 letter or more')
                  .max(35, 'Must be 35 letters or less')
                  .matches(/^[a-zA-Z ]+$/, 'Special characters not allowed'),
                last_name: Yup.string()
                  .min(1, 'Must be 1 letter or more')
                  .max(35, 'Must be 35 letters or less')
                  .matches(/^[a-zA-Z ]+$/, 'Special characters not allowed'),
              }),
            })}
            onSubmit={async (values, actions) => {
              const { risk_assesment_information: riskAssesmentInformation, address } = values
              const formData = new FormData()
              Object.keys(riskAssesmentInformation).map(field => {
                if (riskAssesmentInformation[field]) {
                  field === 'sources_of_funds'
                    ? formData.append(`risk_assesment_information[${field}][]`, riskAssesmentInformation[field])
                    : formData.append(`risk_assesment_information[${field}]`, riskAssesmentInformation[field])
                }
              })

              // TO DO: This needs to be added in order for the API to work properly. To remove later
              formData.append('risk_assesment_information[annual_income_range]', '')

              // Creating data attributes for Title and Last name
              const infoData = new FormData()
              infoData.append('user[personal_info_attributes][title]', address.title)
              infoData.append('user[address_attributes][middle_name]', address.middle_name)
              infoData.append('user[address_attributes][last_name]', address.last_name)

              const result = await Promise.allSettled([
                await updateUserDetails(userId, infoData, 'application/json').catch(error => {
                  toast.error(error?.response?.data?.description)
                  error.response.status === 422 &&
                    setServerValidationErrors(Object.assign(serverValidationErrors, error?.response?.data?.errors))
                }),
                await updateRiskAssesmentInfo(riskAssesmentInfo.customer_id, userId, formData).catch(error => {
                  toast.error(error?.response?.data?.description)
                  error.response.status === 422 &&
                    setServerValidationErrors(Object.assign(serverValidationErrors, error?.response?.data?.errors))
                }),
              ])
              !isEmpty(serverValidationErrors) && actions.setStatus(serverValidationErrors)
              actions.setSubmitting(false)
              setServerValidationErrors({})

              if (result.every(promise => promise.value)) {
                toast.success('Successfully updated')
                actions.setSubmitting(false)
              }
            }}>
            {formik => (
              <Form>
                <SelectField
                  theme="superSwift"
                  label="Title"
                  name="address.title"
                  options={options?.titles}
                  id="titles-select"
                />
                <TextField label="First names" name="address.first_name" isDisabled />
                <TextField label="Middle name" name="address.middle_name" />
                <TextField label="Last name" name="address.last_name" />
                <SelectField
                  theme="superSwift"
                  label="What is the source of funds for transactions?"
                  id="risk_assesment_sources_of_funds-select"
                  name={'risk_assesment_information.sources_of_funds'}
                  options={options?.SOF}
                />
                <Button
                  className={`btn btn-sm btn-success btn-width-200 space-10 mt-4 w-inline-block-button ${
                    formik.isSubmitting ? 'is-submitting' : 'not-submitting'
                  }`}
                  type="submit"
                  data-testid="update-changes-profile-information"
                  disabled={formik.isSubmitting}>
                  {formik.isSubmitting ? <Spinner spinner={false} /> : 'Update changes'}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

ProfileInformation.propTypes = {
  /** Object of riskAssesmentInfo from redux */
  riskAssesmentInfo: PropTypes.object,
  /** Thunk that sets riskAssesmentInfo to redux */
  setRiskAssesmentInformation: PropTypes.func,
  /** Dropdown options */
  sourcesOfFunds: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.number })),
  /** Thunk that updates riskAssesmentInfo on server */
  updateRiskAssesmentInfo: PropTypes.func,
  updateUserDetails: PropTypes.func,

  user: PropTypes.shape({
    account_number: PropTypes.number,
    address: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      middle_name: PropTypes.string,
    }),
    email: PropTypes.string,
    personal_info: PropTypes.object,
  }),
  /** Provides current userId */
  userId: PropTypes.number,
}

const mapStateToProps = ({ user, client }) => ({
  riskAssesmentInfo: user?.data?.riskAssesmentInformation,
  sourcesOfFunds: client?.data?.dropdownSOF || [],
  user: user?.data?.userDetails,
  userId: user?.data?.userId,
})

export default connect(mapStateToProps, { setRiskAssesmentInformation, updateRiskAssesmentInfo, updateUserDetails })(
  ProfileInformation,
)
