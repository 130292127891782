/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Popover from 'react-popover'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
/* Actions */
import { setCurrentBeneficiary } from 'actions/trading'
/* Helpers */
import PaginationTable from 'components/helpers/pagination_table'
import Spinner from 'components/helpers/spinner'
import BeneficiaryFormModal from 'components/pages/shared/modals/beneficiary_form'
/* Assets */
import { ReactComponent as MoreInfo } from 'assets/svg/more_info.svg'
import { ReactComponent as TrashBin } from 'assets/svg/trash_bin.svg'
import { ReactComponent as EditIcon } from 'assets/svg/edit.svg'
/* APIs */
import { index as getAllRecipients, remove as removeBeneByID } from 'api/recipients/cw/beneficiaries'
/* Path */
import { RECIPENTS_FULL_PATH, NEW_BENEFICIARY } from 'routes/paths/private/recipients'
/* Styles */
import './styles.scss'
import Searchbox from 'components/helpers/form_fields/searchbox'

const ignoredFields = ['Bank branch']

const ApprovedRecipients = props => {
  const [data, setData] = useState([])
  const [reload, setReload] = useState(false)
  const dispatch = useDispatch()
  const [filterName, setFilterCurrency] = useState('')

  const columns = [
    {
      header: 'Name',
      sort: false,
      sort_field: 'address_full_name',
    },
    {
      header: 'Payment method',
      sort: false,
      sort_field: 'renumeration_type_name',
    },
    {
      header: 'Country',
      sort: false,
      sort_field: 'account_address_country_code',
    },
    {
      header: 'Currency',
      sort: false,
      sort_field: 'currency_currency_code',
    },
  ]

  useEffect(() => reload && setReload(false), [reload])

  const fetchData = async params => {
    try {
      const res = await getAllRecipients(
        Object.assign(
          { page: 1, per_page: 250, q: { linked_to_cba_eq: 'n', status_eq: 'approved_by_client' } },
          params,
        ),
      )
      const approvedBenes = res?.data?.filter(item => item.status === 'Approved')
      approvedBenes && setData(approvedBenes)
    } catch (error) {
      toast.error(error.response?.data?.description || error.response?.data?.error)
    }
  }

  const renderItem = recipient => {
    const popoverFields = [
      { label: 'First Name', value: recipient?.address?.first_name },
      { label: 'Middle name', value: recipient?.address?.middle_name },
      { label: 'Last name', value: recipient?.address?.last_name },
      { label: 'City', value: recipient?.address?.city },
      { label: 'Post code', value: recipient?.address?.zip },
      { label: 'Country', value: recipient?.address?.country_name },
      { label: 'Email address', value: recipient?.address?.email },
      recipient?.relation_with_customer
        ? {
            label: 'Relationship to sender',
            value: recipient?.relation_with_customer,
          }
        : {},
      recipient?.address?.mobile
        ? {
            label: 'Mobile Number',
            value: `${recipient?.address?.mobile_isd_code} ${recipient?.address?.mobile}`,
          }
        : {},
      { label: 'Bank name', value: recipient?.account?.bank_name },
      { label: 'Bank branch', value: recipient?.account?.branch_name },
      { label: 'Bank country', value: recipient?.account?.address?.country_name },
      { label: 'Account number', value: recipient?.account?.account_number },
      { label: 'SWIFT code', value: recipient?.account?.bic },
      { label: 'Wallet Provider', value: recipient?.wallet_provider?.name },
    ].filter(item => item.value)
    const TableItem = () => {
      const [openPopover, setOpenPopover] = useState(false)
      const [loading, setLoading] = useState(false)
      const [beneModal, setBeneModal] = useState(false)

      const onEditedBene = () => {
        setBeneModal(false)
        setReload(true)
      }

      const renumerationDisplayNameObj = {
        'Cash External': 'Cash',
        'Cash Internal': 'Cash',
        Local: 'Bank',
        SWIFT: 'Bank',
      }
      return (
        <div key={recipient?.user_id} className="rows recipients">
          <Popover
            isOpen={openPopover}
            preferPlace="right"
            body={
              <div style={{ display: openPopover ? 'flex' : 'none' }} className="tool-tip">
                <div className="w-layout-grid tooltip-content">
                  {popoverFields.map(
                    (item, index) =>
                      !ignoredFields.includes(item.label) && (
                        <React.Fragment key={`${item}-${index}`}>
                          <div className="tooltip-label popins-16">{item.label}</div>
                          <div className="tooltip-text popins-16">{item.value}</div>
                        </React.Fragment>
                      ),
                  )}
                </div>
              </div>
            }>
            <div
              className="item-container name"
              onMouseOver={() => setOpenPopover(true)}
              onMouseOut={() => setOpenPopover(false)}>
              {recipient?.address?.first_name || ''} {recipient?.address?.last_name || ''}
              <MoreInfo className="more-info" />
            </div>
          </Popover>
          <div className="item-container text">
            <div className="placeholder">Payment method</div>
            <div>
              {renumerationDisplayNameObj[recipient?.renumeration_type_name] || recipient?.renumeration_type_name || ''}
            </div>
          </div>
          <div className="item-container text">
            <div className="placeholder">Country</div>
            <div>{recipient?.address?.country_name || ''}</div>
          </div>
          <div className="item-container text">
            <div className="placeholder">Currency</div>
            <div>{recipient?.currency_code}</div>
          </div>
          <a onClick={() => props.onViewHistory(recipient?.id)} className="history-link">
            View history
          </a>
          <div className="item-container">
            <EditIcon
              className="table-icon"
              onClick={() => {
                dispatch(setCurrentBeneficiary(recipient))
                props.history.push(`${RECIPENTS_FULL_PATH}/${NEW_BENEFICIARY}`)
              }}
            />
            {!loading ? (
              <TrashBin
                onClick={() => {
                  setLoading(true)
                  removeBeneByID(recipient.id)
                    .then(res => toast.success(res?.data?.description))
                    .catch(error => toast.error(error.response.data.description || error.response.data.error))
                    .finally(() => setReload(true))
                }}
                className="table-icon trash-icon"
              />
            ) : (
              <Spinner text={false} />
            )}
          </div>
          {beneModal && (
            <BeneficiaryFormModal
              show={beneModal}
              onHide={() => setBeneModal(false)}
              onSuccess={onEditedBene}
              isNewBeneficiary={false}
              beneficiary={recipient}
            />
          )}
        </div>
      )
    }

    return <TableItem />
  }

  return (
    !reload && (
      <PaginationTable
        showFilterField={false}
        columns={columns}
        data={data.filter(item =>
          item.address.name
            .toLowerCase()
            .split(' ')
            .join('')
            .match(new RegExp(filterName.toLowerCase().split(' ').join(''), 'g')),
        )}
        getData={fetchData}
        perPage={250}
        renderItem={renderItem}
        testID="approved-beneficiaries"
        title=""
        tableClassName="recipients"
        tableTop={true}
        extraButtonLabel="+ New beneficiary"
        extraButtonPress={() => {
          // clear beneficiary state
          dispatch(setCurrentBeneficiary(null))
          props.history.push(`${RECIPENTS_FULL_PATH}/${NEW_BENEFICIARY}`)
        }}
        extraComponents={
          <Searchbox
            onChange={e => setFilterCurrency(e.target.value)}
            name="filter"
            value={filterName}
            type="text"
            data-testid="payments-table-form-search-searchbox-button"
            placeholder="Search name"
            clearSearch={true}
            disabled={false}
          />
        }
      />
    )
  )
}

ApprovedRecipients.propTypes = {
  history: PropTypes.object,
  onViewHistory: PropTypes.func,
  setBeneModal: PropTypes.func,
}

export default withRouter(ApprovedRecipients)
