/* eslint-disable indent */
import React, { useState, useCallback, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form } from 'formik'
import { RegistrationContext } from 'context/registration/registrationContext'
import isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { userLocalStorage } from 'config/local_storage'
/* Actions */
import { updateRiskAssesmentInfo, updateCurrentUserNotificationSettings, updateCustomerInfo } from 'actions/users'
/* API */
import { notifyOnNewAccountCreation, getBankProductsPromotions } from 'api/accounts/public/registration'
/* Base */
import RegistrationHeader from 'components/base/layouts/registration/header'
import Spinner from 'components/helpers/spinner'
/* Helpers */
import { TextField, CheckboxField } from 'components/helpers/formik_fields'
import ProgressBar from 'components/base/layouts/registration/progress-bar'
/* Utils */
import { steps } from '../register.constants'
/* Paths */
import { NEW_FULL_PATH } from 'routes/paths/private/transactions'

const fieldsObj = {
  money_market: 'Shoprite money market account',
  xtra_savings: 'Xtra savings',
}

// Step 4 section FewMoreThings
export const StepSix = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [dropdowns, setDropdowns] = useState()
  const { tempAuthData } = useContext(RegistrationContext)
  const temporaryAuthData = isEmpty(tempAuthData) ? userLocalStorage.get('tempAuthData') : tempAuthData

  const fetchBankProductsPromotions = useCallback(async () => {
    const response = await getBankProductsPromotions().catch(error => toast.error(error?.response?.data?.description))
    response && setDropdowns(prevState => ({ ...prevState, promotions: response }))
  }, [])

  const addressFields = useSelector(state => state?.user?.data?.addressFields)
  const userDetails = useSelector(state => state?.user?.data?.userDetails)
  const selectedSourceOfFunds = useSelector(state => state?.user?.data?.selectedSourceOfFunds)

  useEffect(() => {
    fetchBankProductsPromotions()
  }, [fetchBankProductsPromotions])

  const initialFields = {
    marketing: {
      sms: false,
      whatsapp: false,
    },
    promotions: {
      money_market: '',
      xtra_savings: '',
    },
    xtra_savings_card_number: '',
  }

  return (
    <RegistrationHeader classContainer="col-8">
      <div className="justify-content-center flex-column spikelet pattern-reg-1 inner-shadows">
        <ProgressBar title={steps[2].text} activeStep="Promotions" />
        <div className="container-1200 flex-align-center">
          <div className="reg-frame">
            <div className="p-2 mt-2">
              <span className="d-flex align-items-start new-header mb-4">Promotions </span>

              <Formik
                enableReinitialize
                initialValues={initialFields}
                validationSchema={Yup.object().shape({
                  xtra_savings_card_number: Yup.string().matches(
                    /^\d{16}$/,
                    'Xtra Savings number must be a 16-digit number',
                  ),
                })}
                onSubmit={async (values, actions) => {
                  const formData = new FormData()
                  Object.keys(values.promotions).map(
                    field =>
                      values.promotions[field] &&
                      formData.append(
                        'risk_assesment_information[bank_product_names][]',
                        dropdowns?.promotions?.find(item => item?.label === fieldsObj[field]).value,
                      ),
                  )
                  const result = await Promise.allSettled([
                    dispatch(
                      updateCustomerInfo({
                        customerId: temporaryAuthData?.account_number,
                        data: { customer: { xtra_savings_card_number: values.xtra_savings_card_number } },
                        id: temporaryAuthData?.admin_user_id,
                      }),
                    ),
                    dispatch(
                      updateCurrentUserNotificationSettings({
                        data: {
                          notification_preference: {
                            marketing: values.marketing,
                            money_market_products: {
                              sms: values.promotions.money_market && values.marketing.sms,
                              whatsapp: values.promotions.money_market && values.marketing.whatsapp,
                            },
                            promotions: {
                              sms: values.promotions.xtra_savings && values.marketing.sms,
                              whatsapp: values.promotions.xtra_savings && values.marketing.whatsapp,
                            },
                          },
                        },
                        userId: temporaryAuthData?.admin_user_id,
                      }),
                    ),
                    ...(!formData.entries().next().done
                      ? [
                          dispatch(
                            updateRiskAssesmentInfo(
                              temporaryAuthData?.account_number,
                              temporaryAuthData?.admin_user_id,
                              formData,
                            ),
                          ),
                        ]
                      : []),
                    notifyOnNewAccountCreation(temporaryAuthData?.account_number).catch(
                      error => error && history.push(NEW_FULL_PATH),
                    ),
                  ])
                  actions.setSubmitting(false)
                  if (result.every(promise => promise.value)) {
                    userLocalStorage.set('isRedirectedFromRegistration', true)
                    history.push(NEW_FULL_PATH)

                    // Google tag manager registration event
                    const gender =
                      userDetails?.personal_info?.gender == null ? null : userDetails?.personal_info?.gender ? 'm' : 'f' // 1/true = male, 0/false = female

                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                      event: 'CompleteRegistration',
                      personal_info: {
                        birthdate: `${userDetails?.birth_date_year}${userDetails?.birth_date_month}${userDetails?.birth_date_day}`,
                        city: (addressFields.city || '').toLocaleLowerCase(),
                        email: userDetails?.email,
                        external_id: values.xtra_savings_card_number,
                        first_name: (userDetails?.address?.first_name || '').toLocaleLowerCase(),
                        gender,
                        last_name: (userDetails?.address?.last_name || '').toLocaleLowerCase(),
                        mobile: userDetails?.address?.mobile_isd_code + userDetails?.address?.mobile,
                      },
                      source_of_funds: selectedSourceOfFunds?.label,
                    })
                  }
                }}>
                {formik => (
                  <Form>
                    <TextField
                      label="Your Xtra Savings Card number"
                      name="xtra_savings_card_number"
                      type="text"
                      placeholder="e.g. 9021093456780151"
                    />
                    <hr className="my-4" />

                    <CheckboxField name="promotions.money_market" top="6px">
                      I agree to receive marketing information with regards to other{' '}
                      <strong>Money Market Products</strong>.
                    </CheckboxField>
                    <br />
                    <CheckboxField name="promotions.xtra_savings" top="6px">
                      I agree to receive information with regards to <strong>Xtra Savings</strong> and promotions.
                    </CheckboxField>

                    <hr className="mt-4" />
                    <div className="my-4 popins-14">I want to receive the above marketing information via:</div>
                    <div className="d-flex justify-content-between pr-5">
                      <CheckboxField name="marketing.sms" top="-2px" message="SMS" />

                      <CheckboxField name="marketing.whatsapp" top="-2px" message="Whatsapp" />
                    </div>
                    <div className="mt-3">
                      <button
                        className="ss-btn red w-100 popins-16"
                        type="submit"
                        data-testid="complete-registration-promotions"
                        disabled={formik.isSubmitting}>
                        {formik.isSubmitting ? <Spinner spinner={false} /> : 'Complete registration'}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </RegistrationHeader>
  )
}
