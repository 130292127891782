import env from '.env'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from 'routes'
import store from 'config/create_store'
import history from 'config/create_history'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import * as serviceWorker from './serviceWorker'
import reportWebVitals from './reportWebVitals'
import 'jquery/src/jquery'
import 'bootstrap/dist/js/bootstrap'
import 'font-awesome/css/font-awesome.min.css'

/* Styles */
import 'assets/styles/global.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'

// Disabled Sentry for local dev
process.env.NODE_ENV !== 'development' &&
  Sentry.init({
    autoSessionTracking: true,
    dsn: env.sentry.dsn,
    environment: env.sentry.currentEnv,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })

ReactDOM.render(
  <React.StrictMode>
    <Root store={store} history={history} />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store
}

reportWebVitals()
