import React from 'react'
import { Link } from 'react-router-dom'
import Facebook from 'assets/images/superswift/facebook.svg'
import Twitter from 'assets/images/superswift/twitter.svg'
import Instagram from 'assets/images/superswift/instagram.svg'
import ShopriteLogo from 'assets/images/superswift/logo-shoprite.png'
import CheckersLogo from 'assets/images/superswift/logo-checkers.png'
import UsaveLogo from 'assets/images/superswift/logo-usave.png'
import Checkers from 'assets/images/superswift/logo-checkers-hyper.png'
import Knect from 'assets/images/superswift/Knect.svg'
import { SIGN_IN_FULL_PATH } from 'routes/paths/public/users'
import { NEW_FULL_PATH as REGISTER_NEW_FULL_PATH } from 'routes/paths/public/register'
import './index.scss'

const Footer = () => {
  return (
    <React.Fragment>
      <div className="footer">
        <div className="footer-columns-container">
          <div className="footer-column">
            <h5 className="heading-5 neutral-0">Company</h5>
            <a href="https://www.superswift.co.za/about-us" className="text-base footer-link">
              About us
            </a>
            <Link
              to={{ pathname: 'https://www.shopriteholdings.co.za' }}
              target="_blank"
              rel="noopener"
              className="text-base footer-link">
              Shoprite Holdings
            </Link>
            <a href="https://www.superswift.co.za/faq" className="text-base footer-link">
              FAQ
            </a>
          </div>
          <div className="footer-column">
            <h5 className="heading-5 neutral-0">My account</h5>
            <Link to={SIGN_IN_FULL_PATH} className="text-base footer-link">
              Login
            </Link>
            <Link to={REGISTER_NEW_FULL_PATH} className="text-base footer-link">
              Create account
            </Link>
            <Link to={SIGN_IN_FULL_PATH} className="text-base footer-link">
              My transfers
            </Link>
            <Link to={SIGN_IN_FULL_PATH} className="text-base footer-link">
              Account details
            </Link>
          </div>
          <div className="footer-column">
            <h5 className="heading-5 neutral-0">Shoprite</h5>
            <div className="footer-link">
              <Link to={{ pathname: 'https://www.facebook.com/ShopriteSA/' }} target="_blank" rel="noopener">
                <img className="social-links w-inline-block" src={Facebook} alt="facebook" />
                <div className="text-base social-text">Facebook</div>
              </Link>
            </div>
            <div className="footer-link">
              <Link to={{ pathname: 'https://twitter.com/Shoprite_SA' }} target="_blank" rel="noopener">
                <img className="social-links w-inline-block" src={Twitter} alt="twitter" />
                <div className="text-base social-text">Twitter</div>
              </Link>
            </div>
            <div className="footer-link">
              <Link to={{ pathname: 'https://www.instagram.com/shopritesa/' }} target="_blank" rel="noopener">
                <img className="social-links w-inline-block" src={Instagram} alt="instagram" />
                <div className="text-base social-text">Instagram</div>
              </Link>
            </div>
          </div>
          <div className="footer-column">
            <h5 className="heading-5 neutral-0">Checkers</h5>
            <div className="footer-link">
              <Link to={{ pathname: 'https://web.facebook.com/checkerssa' }} target="_blank" rel="noopener">
                <img className="social-links w-inline-block" src={Facebook} alt="facebook" />
                <div className="text-base social-text">Facebook</div>
              </Link>
            </div>
            <div className="footer-link">
              <Link to={{ pathname: 'https://twitter.com/CheckersSA' }} target="_blank" rel="noopener">
                <img className="social-links w-inline-block" src={Twitter} alt="twitter" />
                <div className="text-base social-text">Twitter</div>
              </Link>
            </div>
            <div className="footer-link">
              <Link to={{ pathname: 'https://www.instagram.com/checkers_sa/' }} target="_blank" rel="noopener">
                <img className="social-links w-inline-block" src={Instagram} alt="instagram" />
                <div className="text-base social-text">Instagram</div>
              </Link>
            </div>
          </div>
          <div className="footer-column">
            <h5 className="heading-5 neutral-0">Products &amp; services</h5>
            <Link
              to={{ pathname: 'https://www.shoprite.co.za/money-market' }}
              target="_blank"
              rel="noopener"
              className="text-base footer-link">
              Money Market
            </Link>
            <Link
              to={{ pathname: 'https://www.shoprite.co.za/money-market/account/' }}
              target="_blank"
              rel="noopener"
              className="text-base footer-link">
              Money Market Account
            </Link>
            <Link
              to={{ pathname: 'https://www.knectmobile.co.za' }}
              target="_blank"
              rel="noopener"
              className="text-base footer-link">
              k&#x27;nect mobile
            </Link>
            <Link
              to={{ pathname: 'https://www.shoprite.co.za/xtra-savings' }}
              target="_blank"
              rel="noopener"
              className="text-base footer-link">
              Shoprite Xtra Savings
            </Link>
            <Link
              to={{ pathname: 'https://www.checkers.co.za/xtra-savings' }}
              target="_blank"
              rel="noopener"
              className="text-base footer-link">
              Checkers Xtra Savings
            </Link>
          </div>
        </div>
        <div className="container-1200 footer-companies w-container">
          <div className="footer-shoprite-group">
            <Link
              to={{ pathname: 'https://www.shoprite.co.za/' }}
              target="_blank"
              rel="noopener"
              className="footer-shoprite-group-item w-inline-block">
              <img src={ShopriteLogo} width="118" alt="Shoprite Logo" />
            </Link>
            <Link
              to={{ pathname: 'https://www.checkers.co.za/' }}
              target="_blank"
              rel="noopener"
              className="footer-shoprite-group-item w-inline-block">
              <img src={CheckersLogo} width="118" alt="Checkers Logo" />
            </Link>
            <Link
              to={{ pathname: 'https://www.usave.co.za/' }}
              target="_blank"
              rel="noopener"
              className="footer-shoprite-group-item w-inline-block">
              <img src={UsaveLogo} width="80" alt="Usave Logo" />
            </Link>
            <Link
              to={{ pathname: 'https://www.checkers.co.za/' }}
              target="_blank"
              rel="noopener"
              className="footer-shoprite-group-item w-inline-block">
              <img src={Checkers} width="118" alt="Checkers Logo" />
            </Link>
            <Link
              to={{ pathname: 'https://www.shoprite.co.za/knect-services' }}
              target="_blank"
              rel="noopener"
              className="footer-shoprite-group-item w-inline-block">
              <img src={Knect} width="74" alt="Knect Logo" />
            </Link>
          </div>
        </div>
        <div className="container-1200 footer-legal w-container">
          <Link
            to={{ pathname: 'https://www.termsconditions.co.za/' }}
            target="_blank"
            rel="noopener"
            className="footer-legal-link">
            Competition T&amp;Cs
          </Link>
          <Link
            to={{ pathname: 'https://www.termsconditions.co.za/content/TermsConditions/en/cookie-policy.html' }}
            target="_blank"
            rel="noopener"
            className="footer-legal-link">
            Cookie Policy
          </Link>
          <Link
            to={{ pathname: 'https://www.termsconditions.co.za/content/TermsConditions/en/superswift.html' }}
            target="_blank"
            rel="noopener"
            className="footer-legal-link">
            Terms and Conditions
          </Link>
          <Link
            to={{ pathname: 'https://protect-za.mimecast.com/s/PBNyCzm4DAUD7AGMf4ahag' }}
            target="_blank"
            rel="noopener"
            className="footer-legal-link">
            Data Privacy Statement
          </Link>
          <Link to="/assets/PAIA_Manual_2020.pdf" target="_blank" rel="noopener" className="footer-legal-link">
            PAIA Manual
          </Link>
        </div>
        <div className="container-1200 footer-copyright w-container">
          <div className="footer-copyright">(C) Shoprite Group of Companies</div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer
