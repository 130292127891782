import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
/* API */
import { getMobileProviders } from 'api/dropdowns'
/* Helpers */
import { SelectField } from 'components/helpers/formik_fields'
import renderErrors from 'components/helpers/render_errors'
import Spinner from 'components/helpers/spinner'

function MobileWallet({ mobileWallet, onMobileWalletChange, errors, beneficiary }) {
  const [mobileProviders, setMobileProviders] = useState([])
  const { currentBeneficiary } = useSelector(({ trading }) => trading.data) || {}
  const { name: walletProviderName } =
    useSelector(({ trading }) => trading?.data?.currentBeneficiary?.wallet_provider) || {}

  useEffect(() => {
    async function fetchMobileProviders() {
      try {
        await getMobileProviders({
          q: { country_code_eq: beneficiary?.address?.country_code, currency_code_eq: beneficiary?.currency_code },
        }).then(res => {
          setMobileProviders(res?.data.map(item => ({ label: item.name, value: item.name })))
        })

        !isEmpty(currentBeneficiary) &&
          onMobileWalletChange('wallet_provider', currentBeneficiary?.wallet_provider?.name)
      } catch (error) {
        toast.error(error.response?.data.description)
      }
    }
    beneficiary?.address?.country_code && beneficiary?.currency_code && fetchMobileProviders()
  }, [beneficiary?.address?.country_code, beneficiary?.currency_code, beneficiary?.wallet_provider?.name])

  useEffect(() => walletProviderName && onMobileWalletChange('wallet_provider', walletProviderName), [
    mobileProviders?.length,
    walletProviderName,
  ])

  return (
    <div className="px-0">
      {mobileProviders?.length ? (
        Object.keys(mobileWallet).map(input => (
          <div key={input} className="form-group row">
            <div className="col-12">
              <Formik>
                <div>
                  <SelectField
                    label={mobileWallet[input]?.name}
                    name="mobile_wallet"
                    theme="superSwift"
                    /* To change once the API is confirmed */
                    options={mobileProviders}
                    isLoading={!mobileProviders?.length}
                    onChange={option => onMobileWalletChange(mobileWallet[input]?.locale?.split('.')[1], option.value)}
                    /* To change once the API is confirmed */
                    value={mobileProviders?.find(
                      option =>
                        option.value === beneficiary?.mobile_wallet?.wallet_provider ||
                        option.value === beneficiary?.wallet_provider?.name,
                    )}
                    required
                  />
                  {renderErrors(errors['provider.name'])}
                </div>
              </Formik>
            </div>
          </div>
        ))
      ) : (
        <Spinner text={false} />
      )}
    </div>
  )
}

MobileWallet.propTypes = {
  beneficiary: PropTypes.shape({
    address: PropTypes.shape({
      country_code: PropTypes.string,
    }),
    currency_code: PropTypes.string,
    mobile_wallet: PropTypes.shape({
      wallet_provider: PropTypes.string,
    }),
    wallet_provider: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  errors: PropTypes.shape({
    'mobile_wallet.wallet_provider': PropTypes.any,
    'provider.name': PropTypes.any,
  }),
  mobileProviders: PropTypes.arrayOf(PropTypes.object),
  mobileWallet: PropTypes.shape({
    locale: PropTypes.string,
    name: PropTypes.string,
  }),
  onMobileWalletChange: PropTypes.func,
}

export default MobileWallet
