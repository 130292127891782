import fetchApi from 'components/utils/fetch_api'

const addressInfo = (attribute, params) => {
  return fetchApi(`dropdowns/public/account_information/address-${attribute}`, {
    method: 'GET',
    params,
  }).then(res => res?.data)
}

const sourceOfFunds = () => {
  return fetchApi('dropdowns/public/account_information/source_of_funds')
}

const getRegistrationEnabledCountries = () => {
  return fetchApi('accounts/public/countries/registration_enabled').then(res => res?.data)
}

const getOccupationTypes = () => {
  return fetchApi('dropdowns/public/account_information/occupation').then(res => res?.data)
}

const customerCategory = () => {
  return fetchApi('dropdowns/public/account_information/customer_category')
}

const identificationTypes = () => {
  return fetchApi('dropdowns/public/account_information/identification_type')
}

const getPayoutStates = params => {
  return fetchApi('dropdowns/public/beneficiary_information/payout_state', { params })
}

const getPayoutCities = ({ country_state: countryState, ...params }) => {
  return fetchApi(`dropdowns/public/beneficiary_information/payout_city?country_state=${countryState}`, { params })
}

const getPayoutLocations = params => {
  return fetchApi('dropdowns/public/beneficiary_information/payout_location', { params })
}

const getRelationshipTypes = () => {
  return fetchApi('dropdowns/public/beneficiary_information/relationship_type')
}

const getMobileProviders = params => {
  return fetchApi('recipients/cw/wallet_providers', { params })
}

const identificationTypesE4F = () => {
  return fetchApi('dropdowns/public/account_information/identification_types_e4f')
}

const getTitles = () => {
  return fetchApi('dropdowns/public/contact_information/title').then(res => res?.data)
}

const getDocumentTypes = () => {
  return fetchApi('dropdowns/public/documents/document_type').then(res => res?.data)
}

export {
  addressInfo,
  sourceOfFunds,
  getRegistrationEnabledCountries,
  getOccupationTypes,
  identificationTypes,
  customerCategory,
  getPayoutStates,
  identificationTypesE4F,
  getTitles,
  getDocumentTypes,
  getPayoutCities,
  getPayoutLocations,
  getMobileProviders,
  getRelationshipTypes,
}
