import { UserActions } from 'actions'
import { combineReducers } from 'redux'
import { userLocalStorage } from 'config/local_storage'
import PermissionsGenerator from './utils/permissions_generator'
import * as Sentry from '@sentry/browser'

const INITIAL_AUTH_STATE = { sessionExpired: false, sso: false, tokenInformation: {} }

function auth(state = INITIAL_AUTH_STATE, action) {
  switch (action.type) {
    case UserActions.SET_SSO:
      return Object.assign({}, state, {
        sso: true,
      })
    case UserActions.SET_TOKEN_INFORMATION:
      userLocalStorage.set('tokenInformation', action.payload)
      return Object.assign({}, state, {
        tokenInformation: action.payload,
      })
    case UserActions.SESSION_EXPIRED:
      return Object.assign({}, state, {
        sessionExpired: true,
      })
    case UserActions.REFRESH_SESSION:
      return Object.assign({}, state, {
        sessionExpired: false,
      })
    case UserActions.SIGN_OUT:
      Sentry.configureScope(scope => {
        scope.setUser({})
      })
      return INITIAL_AUTH_STATE
    default:
      return state
  }
}

const INITIAL_DATA_STATE = {
  accountSettings: {},
  address: {},
  addressFields: {},
  customerInformation: {},
  identificationInfo: {},
  linkedAccounts: [],
  notificationSettings: {},
  permissions: [],
  riskAssesmentInformation: {},
  securityAnswerList: [],
  securityQuestionList: [],
  selectedSourceOfFunds: null,
  userDetails: {},
}

function data(state = INITIAL_DATA_STATE, action) {
  switch (action.type) {
    case UserActions.SET_CURRENT_USER_DATA:
      const { userId } = action.payload
      Sentry.configureScope(scope => {
        scope.setUser(userId)
      })
      return Object.assign({}, state, {
        ...action.payload,
      })
    case UserActions.SET_CURRENT_USER_PERMISSIONS:
      const permissions = new PermissionsGenerator(action.payload).generateValidPermissions()
      return Object.assign({}, state, {
        permissions,
      })
    case UserActions.SET_CURRENT_USER_ADDRESSES:
      return Object.assign({}, state, {
        address: action.payload,
      })
    case UserActions.SET_CURRENT_USER_LINKED_ACCOUNTS:
      return Object.assign({}, state, {
        linkedAccounts: action.payload,
      })
    case UserActions.SET_CURRENT_USER_ACCOUNT_SETTINGS:
      return Object.assign({}, state, {
        accountSettings: action.payload,
      })
    case UserActions.SET_CURRENT_USER_NOTIFICATION_SETTINGS:
      return Object.assign({}, state, {
        notificationSettings: action.payload,
      })
    case UserActions.SET_CURRENT_USER_SECURITY_ANSWER_LIST:
      return Object.assign({}, state, {
        securityAnswerList: action.payload,
      })
    case UserActions.SET_CURRENT_USER_SECURITY_QUESTION_LIST:
      return Object.assign({}, state, {
        securityQuestionList: action.payload,
      })
    case UserActions.SET_CURRENT_USER_DETAILS:
      return Object.assign({}, state, {
        userDetails: action.payload,
      })
    case UserActions.SET_CURRENT_USER_INFO:
      return Object.assign({}, state, {
        customerInformation: action.payload,
      })
    case UserActions.SET_CURRENT_USER_IDENTIFICATION_INFO:
      return Object.assign({}, state, {
        identificationInfo: action.payload,
      })
    case UserActions.SET_CUSTOMER_RISK_ASSESMENT_INFO:
      return Object.assign({}, state, {
        riskAssesmentInformation: action.payload,
      })
    case UserActions.SET_SOURCE_OF_FUNDS:
      return Object.assign({}, state, {
        selectedSourceOfFunds: action.payload,
      })
    case UserActions.SET_ADDRESS_FIELD:
      return Object.assign({}, state, {
        addressFields: Object.assign({}, state.addressFields, {
          [action.payload.fieldName]: action.payload.value,
        }),
      })
    case UserActions.SIGN_OUT:
      userLocalStorage.clearAll()
      return INITIAL_DATA_STATE
    default:
      return state
  }
}

const UserReducers = combineReducers({ auth, data })

export default UserReducers
