import fetchApi from 'components/utils/fetch_api'

const index = params => {
  return fetchApi('recipients/cw/beneficiaries', { params })
}

const create = data => {
  return fetchApi('recipients/cw/beneficiaries', { data: { beneficiary: data }, method: 'POST' })
}

const createCash = data => {
  return fetchApi('recipients/cw/cash_beneficiaries', { data: { beneficiary: data }, method: 'POST' })
}

const createWallet = data => {
  return fetchApi('recipients/cw/wallet_beneficiaries', { data: { beneficiary: data }, method: 'POST' })
}

const update = (data, beneficiaryId) => {
  return fetchApi(`recipients/cw/beneficiaries/${beneficiaryId}`, { data: { beneficiary: data }, method: 'PUT' })
}

const updateCash = (data, beneficiaryId) => {
  return fetchApi(`recipients/cw/cash_beneficiaries/${beneficiaryId}`, { data: { beneficiary: data }, method: 'PUT' })
}

const updateWallet = (data, beneficiaryId) => {
  return fetchApi(`recipients/cw/wallet_beneficiaries/${beneficiaryId}`, { data: { beneficiary: data }, method: 'PUT' })
}

const show = beneficiaryId => {
  return fetchApi(`recipients/cw/beneficiaries/${beneficiaryId}`)
}

const remove = beneficiaryId => {
  return fetchApi(`recipients/cw/beneficiaries/${beneficiaryId}`, { method: 'DELETE' })
}

export { index, create, update, updateCash, updateWallet, show, remove, createCash, createWallet }
