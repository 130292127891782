/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */
/* eslint-disable indent */
import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { RegistrationContext } from 'context/registration/registrationContext'
import * as Yup from 'yup'
import { userLocalStorage } from 'config/local_storage'
import isEmpty from 'lodash/isEmpty'
import { toast } from 'react-toastify'
/* Actions */
import { updateAddressField } from 'actions/users'
/* API */
import { getDocumentTypes, getRegistrationEnabledCountries } from 'api/dropdowns'
import { updateIdentificationInformation } from 'api/accounts/cw/customers'
/* Base */
import RegistrationHeader from 'components/base/layouts/registration/header'
import Spinner from 'components/helpers/spinner'
import ProgressBar from 'components/base/layouts/registration/progress-bar'
/* Helpers */
import { TextField, SelectField, CalendarField, CheckboxField } from 'components/helpers/formik_fields'
import { useFetchAddressOptions } from 'components/hooks/useFetchAddressOptions'
import { fetchInitialProvince } from 'components/utils/fetch_initial_address_options'
/* Utils */
import { steps } from '../register.constants'
/* Paths */
import { REGISTER_PATH } from 'routes/paths/root'
import { STEP_FIVE } from 'routes/paths/public/register'

const addressAttributes = { province: 'Province', city: 'City', suburb: 'Suburb', zip: 'Zip' }

// Step 4 section PhysicalAddress
export const StepFourPhysicalAddress = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [countries, setCountries] = useState()
  const [idIssuingCountryList, setIdIssuingCountryList] = useState()
  const [serverValidationErrors, setServerValidationErrors] = useState({})

  const { dropdownCountries } = useSelector(({ client }) => client.data)

  const [
    dropdowns,
    setDropdowns,
    isLoading,
    setIsLoading,
    fetchDropdownOptions,
    addressAttributesRef,
    newFormikStateObj,
  ] = useFetchAddressOptions(addressAttributes)

  !dropdownCountries && toast.error('Server not responding')

  const nationalities = () => {
    return dropdownCountries.map(country => ({
      label: country.nationality,
      value: country.nationality_id,
    }))
  }

  const { currentCustomerCategory, registerFlow, DropdownFetcher, updateRegData, tempAuthData } = useContext(
    RegistrationContext,
  )
  const isNative = currentCustomerCategory?.isNative || userLocalStorage.get('currentCustomerCategory').isNative

  useEffect(() => {
    if (dropdowns?.id_issuing_country) {
      setIdIssuingCountryList(dropdowns?.id_issuing_country)
    }
  }, [dropdowns?.id_issuing_country])

  const fetchDocumentTypes = useCallback(async () => {
    const response = await getDocumentTypes().catch(error => toast.error(error.response?.data.description))
    const documentTypes = response?.filter(docType => docType.label !== 'South African ID')
    response && setDropdowns(prevState => ({ ...prevState, documentTypes }))
  }, [])

  useEffect(() => {
    fetchInitialProvince(setDropdowns, setIsLoading)
    !isNative && fetchDocumentTypes()
  }, [fetchDocumentTypes, fetchInitialProvince, isNative])

  // TODO: remove when API will be fixed
  useEffect(() => {
    async function fetchRegistrationEnabledCountries() {
      try {
        const res = await getRegistrationEnabledCountries()
        res &&
          setCountries(
            res.map(country => ({
              id: country.id,
              label: country.name,
              value: country.code,
            })),
          )
      } catch (error) {
        toast.error(error.response?.data.description)
      }
    }
    fetchRegistrationEnabledCountries()
  }, [])

  const registrationFlow = isEmpty(registerFlow) ? userLocalStorage.get('registerFlow') : registerFlow
  const temporaryAuthData = isEmpty(registerFlow) ? userLocalStorage.get('tempAuthData') : tempAuthData
  // inputs from the physical_address section
  const inputs = registrationFlow?.step_2?.customer_information.sections.physical_address.inputs

  inputs && DropdownFetcher(inputs, setDropdowns, ['country'])

  // inputs from the identification_information section witch will be rendered conditionaly
  const additionalInputs = registrationFlow?.step_2?.customer_information.sections.identification_information.inputs

  additionalInputs && DropdownFetcher(additionalInputs, setDropdowns)

  const identificationDocumentsInputs =
    registrationFlow?.step_2?.customer_information.sections?.identification_documents.inputs

  /**
   * Returns default value for dropdown. Suggest using this after successfully awaited an array
   * @param {Array} list Array with objects of label and value
   * @param {any} label The label to compare in the list
   * @param {any} value The value in object to compare in the list
   */
  const getDefaultValueForDropdown = (list, label = '', value = null) => {
    if (list && label) {
      return list.find(country => country.label === label)
    }
    if (list && value) {
      return list.find(country => country.value === value)
    }
    return null
  }

  /** Function for destructuring the date */
  const getDate = (string, prefix) =>
    // eslint-disable-next-line camelcase
    (([date_year, date_month, date_day]) => ({
      [`${prefix}_date_year`]: date_year,
      [`${prefix}_date_month`]: date_month,
      [`${prefix}_date_day`]: date_day,
    }))(string.split('-'))

  if (!dropdowns?.id_issuing_country || !dropdowns?.country) {
    return <Spinner />
  }
  const handleOnChange = (option, dropdownSection, fieldName, { values, setValues }) => {
    const assigningMethod = dropdownSection === 'address'
    const { postal_address: postalAddress, ...addressValues } = values
    const formikValues = assigningMethod ? addressValues : postalAddress

    assigningMethod
      ? setValues(Object.assign(values, newFormikStateObj(fieldName, option.value)))
      : setValues({
          ...values,
          [dropdownSection]: {
            ...values[dropdownSection],
            ...newFormikStateObj(fieldName, option.value),
          },
        })
    fieldName !== 'zip' && fetchDropdownOptions(fieldName, option.value, formikValues, dropdownSection)

    dispatch(updateAddressField({ fieldName, value: option.value }))
  }

  const onIdentificationChange = (option, { setFieldValue }) => {
    setFieldValue('id_card.identity_type_id', option.value)
    if (option.label === 'SA Temporary Residence Permit') {
      setFieldValue(
        'id_issuing_country',
        getDefaultValueForDropdown(dropdowns?.id_issuing_country, 'South Africa').value,
      )
      setIdIssuingCountryList(dropdowns?.id_issuing_country.filter(country => country.label === 'South Africa'))
    } else {
      setIdIssuingCountryList(dropdowns?.id_issuing_country.filter(country => country.label !== 'South Africa'))
      setFieldValue('id_issuing_country', '')
    }
  }
  return (
    <RegistrationHeader classContainer="col-8">
      <div className="justify-content-center flex-column spikelet pattern-reg-1 inner-shadows">
        <ProgressBar title={steps[2].text} activeStep="Address details" />
        <div className="container-1200 flex-align-center">
          <div className="reg-frame">
            <div className="p-2 mt-2">
              <span className="d-flex align-items-start new-header mb-4">Identification information </span>

              <span className="d-flex align-items-start popins-16 my-4">Physical address</span>
              <Formik
                innerRef={addressAttributesRef}
                initialValues={{
                  address_line_1: '',
                  city: '',
                  country_code: getDefaultValueForDropdown(countries, 'South Africa')?.value,
                  id_card: { expiry_date_year: '', identity_type_id: '', number: '' },
                  id_issuing_country: '',
                  nationality_name: isNative ? getDefaultValueForDropdown(nationalities(), 'South African').value : '',
                  postal: true,
                  postal_address: {
                    address_line_1: '',
                    city: '',
                    country_code: getDefaultValueForDropdown(countries, 'South Africa')?.value,
                    province: '',
                    suburb: '',
                    zip: '',
                  },

                  province: '',
                  suburb: '',
                  zip: '',
                }}
                validationSchema={Yup.object().shape({
                  address_line_1: Yup.string()
                    .max(70, 'Must be 70 characters or less')
                    .min(2, 'Must be 2 characters or more')
                    .matches(/^[a-zA-Z0-9 -]*$/, 'Special characters not allowed'),
                  postal_address: Yup.object().shape({
                    address_line_1: Yup.string()
                      .max(70, 'Must be 70 characters or less')
                      .min(2, 'Must be 2 characters or more')
                      .matches(/^[a-zA-Z0-9 -]*$/, 'Special characters not allowed'),
                  }),
                })}
                onSubmit={async (values, { setStatus, setSubmitting }) => {
                  const {
                    postal,
                    postal_address: postalAddress,
                    id_issuing_country: idIssuingCountry,
                    id_card: idCard,
                    nationality_name: nationalityName,
                    ...restValues
                  } = values

                  const documentExpireDate = idCard?.expiry_date_year
                    ? getDate(idCard.expiry_date_year, 'expiry')
                    : getDate('--', 'expiry')

                  const updateRegistrationObj = {
                    address: {
                      ...restValues,
                      postal,
                      postal_address_attributes: postal ? restValues : postalAddress,
                    },
                  }
                  const nationalityObj = {
                    nationality_name: dropdownCountries?.find(country => country?.nationality_id === nationalityName)
                      ?.nationality,
                  }
                  const identificationObj = {
                    id_information: {
                      country_code: idIssuingCountry,
                      id_card_attributes: {
                        ...idCard,
                        ...documentExpireDate,
                      },
                      ...nationalityObj,
                    },
                  }
                  const updateIdentificationObj = isNative
                    ? { id_information: { ...nationalityObj } }
                    : identificationObj
                  const result = await Promise.allSettled([
                    updateRegData(updateRegistrationObj, setStatus).catch(error => {
                      if (error.response.status === 422) {
                        setServerValidationErrors(Object.assign(serverValidationErrors, error?.response?.data?.errors))
                      } else {
                        toast.error(error.response?.data.description)
                      }
                    }),
                    updateIdentificationInformation(temporaryAuthData?.admin_user_id, updateIdentificationObj).catch(
                      error => {
                        if (error.response.status === 422) {
                          setServerValidationErrors(
                            Object.assign(serverValidationErrors, error?.response?.data?.errors),
                          )
                        } else {
                          toast.error(error?.response?.data?.description)
                        }
                      },
                    ),
                  ])
                  !isEmpty(serverValidationErrors) && setStatus(serverValidationErrors)
                  setSubmitting(false)
                  setServerValidationErrors({})
                  result.every(promise => promise.value) && history.push(`${REGISTER_PATH}/${STEP_FIVE}`)
                }}>
                {formik => (
                  <Form>
                    {inputs?.address_line_1?.enabled && (
                      <TextField
                        label={inputs?.address_line_1?.name}
                        name={'address_line_1'}
                        type={inputs?.address_line_1?.type}
                        placeholder={inputs?.address_line_1?.placeholder}
                        required={inputs?.address_line_1?.required}
                      />
                    )}

                    {Object.keys(addressAttributes).map(inputName => {
                      if (inputs?.[inputName]?.enabled) {
                        return (
                          <SelectField
                            key={`address.${inputName}`}
                            label={inputs?.[inputName].name}
                            name={`${inputName}`}
                            theme="superSwift"
                            options={dropdowns?.address?.[inputName]}
                            isLoading={isLoading?.address?.[inputName] || isLoading?.address?.[inputName] === undefined}
                            required={inputs?.[inputName].required}
                            onChange={option => handleOnChange(option, 'address', inputName, formik)}
                          />
                        )
                      }
                    })}
                    <hr className="my-4" />
                    <span className="d-flex align-items-start popins-16 mb-4">Postal address</span>
                    <CheckboxField name="postal" message="My postal address is the same as physical address" />
                    {!formik.values.postal && (
                      <React.Fragment>
                        {inputs?.address_line_1?.enabled && (
                          <TextField
                            label={inputs?.address_line_1?.name}
                            name={'postal_address.address_line_1'}
                            type={inputs?.address_line_1?.type}
                            placeholder={inputs?.address_line_1?.placeholder}
                            required={inputs?.address_line_1?.required}
                          />
                        )}

                        {Object.keys(addressAttributes).map(inputName => {
                          if (inputs?.[inputName]?.enabled) {
                            return (
                              <SelectField
                                key={`postal_address.${inputName}`}
                                label={inputs?.[inputName]?.name}
                                name={`postal_address.${inputName}`}
                                theme="superSwift"
                                options={dropdowns?.postal_address?.[inputName]}
                                isLoading={
                                  isLoading?.postal_address?.[inputName] ||
                                  isLoading?.postal_address?.[inputName] === undefined
                                }
                                required={inputs?.[inputName]?.required}
                                onChange={option => handleOnChange(option, 'postal_address', inputName, formik)}
                              />
                            )
                          }
                        })}
                      </React.Fragment>
                    )}
                    <hr className="my-4" />
                    {!isNative && (
                      <React.Fragment>
                        <SelectField
                          label="Identification"
                          name="id_card.identity_type_id"
                          theme="superSwift"
                          options={dropdowns?.documentTypes}
                          isLoading={!dropdowns?.documentTypes}
                          onChange={option => onIdentificationChange(option, formik)}
                          required
                        />
                        <div className="form-control-container">
                          <div className="row">
                            <div className="col-md-12 d-flex">
                              {additionalInputs?.passport_number?.enabled && (
                                <div className="w-100">
                                  <TextField
                                    label={additionalInputs?.passport_number?.name}
                                    name="id_card.number"
                                    type={additionalInputs?.passport_number?.type}
                                    placeholder={additionalInputs?.passport_number?.placeholder}
                                    required={additionalInputs?.passport_number?.required}
                                  />
                                </div>
                              )}
                              {dropdowns?.documentTypes?.find(
                                docType => docType.value === formik.values?.id_card?.identity_type_id,
                              )?.expiry_date && (
                                <div className="w-100 ml-2">
                                  <CalendarField
                                    label={additionalInputs?.passport_expiry_date?.name}
                                    name="id_card.expiry_date_year"
                                    calendarMinDate={new Date()}
                                    required={additionalInputs?.passport_expiry_date?.required}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {additionalInputs?.id_issuing_country?.enabled && (
                          <SelectField
                            label={additionalInputs?.id_issuing_country?.name}
                            name="id_issuing_country"
                            theme="superSwift"
                            options={idIssuingCountryList}
                            isLoading={!idIssuingCountryList}
                            required={additionalInputs?.id_issuing_country?.required}
                          />
                        )}
                      </React.Fragment>
                    )}
                    <SelectField
                      label="Nationality"
                      name="nationality_name"
                      theme="superSwift"
                      options={nationalities()}
                      required={identificationDocumentsInputs?.nationality?.required}
                    />
                    {inputs?.country?.enabled && (
                      <SelectField
                        label={inputs?.country?.name}
                        name={'country_code'}
                        theme="superSwift"
                        options={countries}
                        isLoading={!countries}
                        required={inputs?.country?.required}
                        onChange={option => {
                          const values = formik.values
                          formik.setValues({
                            ...values,
                            country_code: option.value,
                            postal_address: { ...values.postal_address, country_code: option.value },
                          })
                        }}
                        defaultValue={getDefaultValueForDropdown(countries, 'South Africa')}
                      />
                    )}
                    <div className="form-group mt-4">
                      <button disabled={formik.isSubmitting} type="submit" className="ss-btn red w-100 popins-16">
                        {formik.isSubmitting ? <Spinner spinner={false} /> : 'Next'}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </RegistrationHeader>
  )
}
