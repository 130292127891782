export default {
  authenticationStrategy: 'mobile_phone_or_member_id',
  backend: {
    url: 'https://api.shoprite.play.cymonzfxip.com/api',
    version: 'v1',
  },
  mock: {
    CBAList: false,
    createBeneficiary: {
      renumerationOptions: {
        externalCash: false,
        mobileWallet: false,
      },
    },
    debitCardsIndex: false,
    user: {
      linkedAccounts: false,
    },
  },
  ozow: {
    is_test: false,
    url: 'https://stagingpay.ozow.com/',
  },
  peach: {
    url: 'https://test.oppwa.com',
  },
  retail: {
    theme: 'tdb',
  },
  retailLayout: {
    transactionSequence: {
      fundingAccountPosition: '',
      recipientPosition: '',
    },
  },
  sentry: {
    currentEnv: 'capetown-shoprite-dev',
    dsn: 'https://b10f599f3a9a448fa1831276e9fa8fcc@o490342.ingest.sentry.io/5554120',
  },
  springBack: {
    message: {
      content: '',
      title: '',
    },
    method: '',
    url: '',
  },
  tenant: {
    uid: '',
  },
}
