import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import verifyNationalIdNumber from 'south-africa-national-id-validation'
import * as Yup from 'yup'
import { isEmpty } from 'lodash'
/** Actions */
import { setIdentificationInformations, updateIdentificationInfo } from 'actions/users'
/* API */
import { uploadIdentificationScan } from 'api/accounts/public/registration'
import { getAccountDocuments } from 'api/accounts/cw/customers'
import { identificationTypes } from 'api/dropdowns'
/* Helpers */
import { TextField, SelectField, CalendarField } from 'components/helpers/formik_fields'
import Spinner from 'components/helpers/spinner'
import FileInput from 'components/helpers/form_fields/input_file'

/**
 * Component for Account Identification
 */
export const IdentificationInformation = ({
  identificationInfo,
  user,
  userId,
  setIdentificationInformations,
  updateIdentificationInfo,
}) => {
  const [file, setFile] = useState({})
  const [errors, setErrors] = useState([])
  const [identificationType, setIdentificationType] = useState({})
  const [options, setDropdownOptions] = useState()

  useEffect(() => {
    if (isEmpty(identificationInfo)) {
      setIdentificationInformations(user.account_number, userId)
    }
  }, [userId, user.account_number])

  useEffect(() => {
    getAccountDocuments(user.account_number, 'individual_govt_issued_id')
    async function fetchIdentificationTypes() {
      const response = await identificationTypes().catch(error => toast.error(error.response?.data?.description))
      response &&
        setDropdownOptions(prevState => ({
          ...prevState,
          identificationTypes: response?.data.filter(idType => idType.label !== null),
        }))
    }
    fetchIdentificationTypes()
  }, [userId])

  const handleFileUpload = e => {
    if (e.target.files.length) {
      setFile({ ...file, [e.target.name]: e.target.files[0] })
      setErrors(errors.filter(error => e.target.name !== error))
    }
  }
  const uploadFile = async () => {
    setErrors([])
    if (!file.individual_govt_issued_id) {
      setErrors(errors => [...errors, 'individual_govt_issued_id'])
    } else {
      const response = await uploadIdentificationScan(
        file.individual_govt_issued_id,
        userId,
        'individual_govt_issued_id',
      ).catch(error => {
        toast.error(error.response?.data.description)
      })
      if (response) {
        toast.success(response.description)
        setFile({})
      }
    }
  }
  const validationShape = {
    customer_category: Yup.object().shape({}),
  }
  if (identificationType.value) {
    validationShape.admin_user_attributes = Yup.object().shape({
      south_african_id: Yup.string().test('ID Test', 'ID is invalid', value =>
        verifyNationalIdNumber({ number: value || ' ' }),
      ),
    })
  }
  const validationSchema = Yup.object().shape(validationShape).nullable()

  /** Function for destructuring the date */
  const getDate = (string, prefix) =>
    // eslint-disable-next-line camelcase
    (([date_year, date_month, date_day]) => ({
      [`${prefix}_date_year`]: date_year,
      [`${prefix}_date_month`]: date_month,
      [`${prefix}_date_day`]: date_day,
    }))(string.split('-'))

  const passportValue = options?.identificationTypes?.find(idType => idType.label === 'Passport').value

  return (
    <div className="row equal-cols">
      <div className="col col-md-6">
        <div className="justify-content-between panel-wrapper p-4">
          <h6 className="font-bold">Identification</h6>
          <hr className="mt-4 mb-4" />
          <Formik
            initialValues={{
              expiry_date: '',
              id_card: {
                identification_type_id: identificationInfo?.id_card?.identity_type_id,
                number: identificationInfo?.id_card?.number,
              },
            }}
            validationSchema={Yup.object().shape({
              id_card: Yup.object().shape({
                number: Yup.string()
                  .max(15, 'Must be 15 characters or less')
                  .matches(/^[a-zA-Z0-9 -]*$/, 'Special characters not allowed'),
              }),
            })}
            onSubmit={async (values, actions) => {
              const documentExpireDate = values.expiry_date ? getDate(values.expiry_date, 'expiry') : getDate('--')

              const updateIdentificationInfoObj = {
                id_information: {
                  id_card_attributes: {
                    ...values.id_card,
                    ...documentExpireDate,
                  },
                },
              }

              const response = await updateIdentificationInfo(updateIdentificationInfoObj, user.account_number).catch(
                error => {
                  if (error.response.status === 500) {
                    toast.error(error.response?.data.description)
                  } else {
                    actions.setStatus(error.response.data.errors)
                  }
                },
              )
              if (response) {
                toast.success('Successfully updated')
                actions.setSubmitting(false)
              }
            }}>
            {formik => (
              <Form>
                <SelectField
                  label="Identification type"
                  name="id_card.identification_type_id"
                  options={options?.identificationTypes}
                  isLoading={!options?.identificationTypes}
                />
                <div className="form-control-container">
                  <div className="row">
                    <div
                      className={`${
                        passportValue === formik.values.id_card.identification_type_id ? 'col-md-6' : 'col-12'
                      }`}>
                      <TextField label="Document ID" name="id_card.number" placeholder="Document ID" />
                    </div>
                    <div className="col-md-6 pl-0">
                      {passportValue === formik.values.id_card.identification_type_id && (
                        <CalendarField label="Expiry date" name="expiry_date" calendarMinDate={new Date()} />
                      )}
                    </div>
                  </div>
                </div>
                <label className="control-label">Upload document</label>
                <div className="row">
                  <div className="col-xl-9">
                    <FileInput
                      onChange={handleFileUpload}
                      name="individual_govt_issued_id"
                      isValid={!!file?.individual_govt_issued_id}
                      error={errors.some(error => error === 'individual_govt_issued_id')}
                    />
                  </div>
                  <Button onClick={uploadFile} className="btn btn-sm btn-secondary mb-3 ">
                    Upload
                  </Button>
                </div>
                <Button
                  className="btn btn-sm btn-success btn-width-200 space-10 mt-4"
                  type="submit"
                  data-testid="update-changes-identification-information"
                  disabled={formik.isSubmitting}>
                  {formik.isSubmitting ? <Spinner spinner={false} /> : 'Update changes'}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="col col-md-6">
        <div className="justify-content-between panel-wrapper p-4">
          <h6 className="font-bold">Identification</h6>
          <hr className="mt-4 mb-4" />
          <Formik
            initialValues={{
              admin_user_attributes: {
                south_african_id: '',
              },
              customer_category: '',
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              const response = await updateIdentificationInfo({ user: { ...values } }, userId).catch(error => {
                if (error.response.status === 500) {
                  toast.error(error.response?.data.description)
                } else {
                  actions.setStatus(error.response.data.errors)
                }
              })
              if (response) {
                toast.success('Successfully updated')
                actions.setSubmitting(false)
              }
            }}>
            {formik => (
              <Form>
                <SelectField
                  label="Identification type"
                  name="customer_category"
                  options={[
                    { label: 'South African ID', value: true },
                    { label: 'Temporary Permit', value: false },
                  ]}
                  onChange={option => {
                    formik.setFieldValue('customer_category', option.value)
                    setIdentificationType(option)
                  }}
                />
                <TextField
                  label="ID"
                  name="admin_user_attributes.south_african_id"
                  placeholder="Document ID"
                  required
                />
                <Button
                  className="btn btn-sm btn-success btn-width-200 space-10 mt-4"
                  type="submit"
                  disabled={formik.isSubmitting}>
                  {formik.isSubmitting ? <Spinner spinner={false} /> : 'Update changes'}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

IdentificationInformation.propTypes = {
  /** Object of identificationInfo from redux */
  identificationInfo: PropTypes.object,
  /** Thunk that sets identificationInfo to redux */
  setIdentificationInformations: PropTypes.func,
  /** Thunk that updates identificationInfo on server */
  updateIdentificationInfo: PropTypes.func,
  user: PropTypes.shape({
    account_number: PropTypes.number,
    address: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      middle_name: PropTypes.string,
    }),
  }),
  /** Provides current userId */
  userId: PropTypes.number,
}

const mapStateToProps = ({ user }) => ({
  identificationInfo: user.data.identificationInfo,
  user: user.data.userDetails,
  userId: user.data.userId,
})

export default connect(mapStateToProps, { setIdentificationInformations, updateIdentificationInfo })(
  IdentificationInformation,
)
