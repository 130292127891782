import env from '.env'
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import { Link, withRouter, NavLink, useLocation } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
/* Api */
import { signOut as signOutRequest } from 'api/accounts/cw/users'
/* HOC */
import WithDataSessionStore from 'components/HOC/with_data_session_store'
import WithCurrentUser from 'components/HOC/with_current_user'
/* Paths */
import { SIGN_OUT_PATH as SSO_SIGN_OUT_PATH } from 'routes/paths/public/sso'
import { NEW_FULL_PATH as TRANSACTION_NEW_FULL_PATH } from 'routes/paths/private/transactions'
/* Components */
import Spinner from 'components/helpers/spinner'
import SwitchAccountModal from './switch_account_modal'
import Logo from 'assets/images/superswift/Logo-No-White.svg'
import { Menu } from '../menu.content'
/* Styles */
import './index.scss'
import { RECIPENTS_FULL_PATH } from 'routes/paths/private/recipients'

/**
 * Renders UI header component Most commonly used accross the website
 */
const MainHeader = ({ tenantName, logo, supportUrl, isAccountSelected = false, currentUser, ...props }) => {
  /**
   * @type {boolean} - Indicates if user is allow to switch account
   */
  const canSwitchAccount = currentUser.linkedAccounts.length > 1
  /**
   * @type {boolean} - Shows modal to allow the user to change account
   */
  const [isSwitchAccountModalOpen, setIsSwitchAccountModalOpen] = useState(false)
  /**
   * @type {boolean} - Shows logout modal for users to select continue logging out or cancel
   */
  const [isLogoutOpen, setIsLogoutOpen] = useState(false)
  const [isSubNavbarOpen, setSubNavbarOpen] = useState(false)
  const buttonRef = useRef()
  /**
   Deletes user data from current session
  * Note: redirection after sign in is handled automatically by routes
  * @see src/routes/shared/auth_route/index.jsx For more information
  */
  const handleSignOut = () => {
    signOutRequest().finally(() => {
      props.removeDataSession()
    })
  }
  /**
    Toggles Modal associated to Account switching
  */
  const toggleSwitchAccountModal = () => {
    setIsSwitchAccountModalOpen(!isSwitchAccountModalOpen)
  }
  /**
   * Toggles Logout modal when users click 'Sign out'
   */
  const handleOpenLogout = () => setIsLogoutOpen(!isLogoutOpen)
  const activePath = useLocation().pathname

  const renderMobileNav = () => (
    <React.Fragment>
      <li className="nav-2-link">
        <NavLink to={`${RECIPENTS_FULL_PATH}`}>Beneficiaries</NavLink>
      </li>
      <li className="nav-2-link">
        <NavLink to="/retail/history-transfers">Transactions</NavLink>
      </li>
      <li className="nav-2-link">
        <NavLink to="/retail/users/profile-information">My profile</NavLink>
      </li>
      <li className="nav-2-link">
        <NavLink to="/retail/users/profile-information">Support</NavLink>
      </li>
      <li className="nav-2-link">
        <a onClick={handleOpenLogout}>Log out</a>
      </li>
      {activePath !== '/retail/transactions/new' && (
        <li className="cta-button nav-2 w-inline-block">
          <NavLink style={{ color: '#fff' }} to={TRANSACTION_NEW_FULL_PATH}>
            Send Money
          </NavLink>
        </li>
      )}
    </React.Fragment>
  )

  return (
    <div className="new-inner-header">
      {<SwitchAccountModal show={isSwitchAccountModalOpen} closeModal={toggleSwitchAccountModal} />}
      {env.springBack.url && (
        <div className="row">
          <Link className="btn btn-sm btn-warning mt-2 text-white" to={SSO_SIGN_OUT_PATH}>
            Back to Internet Banking
          </Link>
        </div>
      )}
      <div className="navbar">
        <div className="navigation-2 w-nav">
          <div className="navbar-content">
            <a href="https://www.superswift.co.za/" title={tenantName}>
              <img alt={tenantName} className="brand-logo small" src={Logo} />
            </a>
            <nav className="nav-menu full w-nav-menu">
              <div ref={buttonRef} className="nav-burger" onClick={() => setSubNavbarOpen(!isSubNavbarOpen)}>
                <i className="fas fa-bars"></i>
              </div>
              <div className="nav-block">
                <Menu itemClass="mobile" show={isSubNavbarOpen} setSubNavbarOpen={setSubNavbarOpen} />
              </div>
            </nav>

            <button
              className="navbar-toggler w-nav-button"
              ref={buttonRef}
              type="button"
              data-toggle="collapse"
              data-target="#collapse_target"
              aria-controls="collapse_target"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav ml-auto">
                {supportUrl && (
                  <li className="nav-item">
                    <a
                      href={supportUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-transparent btn btn-light text-muted">
                      <span className="fa fa-life-ring"></span>
                      Support
                    </a>
                  </li>
                )}
                {env.springBack.url ? (
                  <React.Fragment>
                    {canSwitchAccount && isAccountSelected && (
                      <li className="nav-item">
                        <button
                          onClick={toggleSwitchAccountModal}
                          className="text-muted bg-transparent border-0 text-muted btn btn-light">
                          Switch Account
                        </button>
                      </li>
                    )}
                    <button
                      onClick={handleOpenLogout}
                      className="text-muted bg-transparent border-0 text-muted btn btn-light">
                      Sign Out
                    </button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {isEmpty(currentUser.userDetails?.address) ? (
                      <Spinner text={false} />
                    ) : (
                      <button
                        onClick={handleOpenLogout}
                        type="button"
                        name="to-logout"
                        className="bg-transparent text-muted btn btn-light mt-2">
                        <span className="fas fa-sign-out-alt mr-2"></span>
                        Sign out
                      </button>
                    )}
                  </React.Fragment>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className={`subnav ${isSubNavbarOpen ? 'show' : ''}`}>
          <div className="subnav-container">
            <NavLink className="subnav-link w-inline-block" to="/retail/users/profile-information">
              My profile
            </NavLink>
            <a className="subnav-link w-inline-block" onClick={handleOpenLogout}>
              Log out
            </a>
          </div>
        </div>
        <div
          className={`mobile-nav-overlay ${isSubNavbarOpen ? 'show-mobile' : ''}`}
          onClick={() => setSubNavbarOpen(!isSubNavbarOpen)}>
          <div className={`subnav-container-mobile ${activePath === '/retail/transactions/new' ? 'w-mobile' : ''}`}>
            {renderMobileNav()}
          </div>
        </div>
      </div>

      <Modal
        name="logout"
        contentClassName="well-dark w-75 text-dark"
        show={isLogoutOpen}
        onHide={handleOpenLogout}
        backdrop="static">
        <div className="bg-white m-2 px-4">
          <Modal.Header className="border-bottom-6 px-0">
            <h6 className="ml-0 mt-3 font-bold">Logging out...</h6>
          </Modal.Header>
          <Modal.Body className="px-0 mt-4">
            <p className="font-bold">Are you sure you want to logout?</p>
          </Modal.Body>
          <Modal.Footer className="px-0 d-flex justify-content-start border-0">
            <Button name="confirm-logout" className="m-0 btn btn-sm btn-danger" onClick={handleSignOut}>
              Yes, logout
            </Button>
            <Button name="cancel-logout" variant="default" onClick={handleOpenLogout}>
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => {
  const { tenant } = state.client.data
  const { logo, name, support_url: supportUrl } = tenant || {}
  return {
    logo,
    supportUrl,
    tenantName: name,
  }
}

MainHeader.propTypes = {
  /** Current authenticated user data {@link withCurrentUser} */
  currentUser: PropTypes.shape({
    /** Account associated to the user */
    linkedAccounts: PropTypes.array,
    /** Name object of the user */
    userDetails: PropTypes.shape({
      address: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string,
      }),
    }),
  }),
  /** Provides several attributes/functions for managing session history {@link withRouter} */
  history: PropTypes.object.isRequired,
  /** Indicates if a user account is selected */
  isAccountSelected: PropTypes.bool,
  /** Object to show an image */
  logo: PropTypes.object,
  /** Signs out the user */
  removeDataSession: PropTypes.func,
  /** URL to access a support website or path */
  supportUrl: PropTypes.string,
  /** Website name */
  tenantName: PropTypes.string,
}

export default connect(mapStateToProps, null)(withRouter(WithCurrentUser(WithDataSessionStore(MainHeader))))
